import React, { useState, useCallback, useEffect,useLayoutEffect, useRef } from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

// REDUX
import { postContact } from '../../slices/tokenReducer'; 
import  store  from '../../store'


// REACT ICONS
import { IoIosArrowForward } from 'react-icons/io'

// SELF IMPORTS
import './Contact.css'

gsap.registerPlugin(ScrollTrigger);
interface ContactInit {
  Title_1: string
  Info_1: string  
  Checkbox_1: string  
  Link_Text: string
  Link: string
}

const Contact = ({Title_1,Info_1,Checkbox_1, Link_Text,Link}:ContactInit) => {
  const { REACT_APP_SITE_KEY }:any = process.env; 
  type AppDispatch = typeof store.dispatch
  const useAppDispatch = () => useDispatch<AppDispatch>()
  const dispatch = useAppDispatch()    
  const navigate = useNavigate()
  const location = useLocation()
  const contact_ref = useRef(null)




  //* States
  interface formdatainit {
    [key:string]:any
    firstName:string
    lastName:string
    email:string
    phone:string
    message:string   
    approve_storage: boolean
  }
  const [formData, setFormData] = useState<formdatainit>({
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    message:"",
    approve_storage: false
  })

  const [message, setMessage] = useState<string>("")

  interface inputerrorinit {[key:string]:string}
  const [inputErrorBorder, setInputErrorBorder] = useState<inputerrorinit>({
    firstName:"rgb(188, 59, 59)",
    lastName:"0px solid rgba(128, 128, 128, 0.26)",
    email:"1px solid rgba(128, 128, 128, 0.26)"
  })



  //* Functions

  // Change values in the Form (formData)
  const HandleChange = (e:any) => {   
    const { name, value } = e?.target    
    setFormData(prev => ({...prev,
      [name]:value
    }))
  }
  const HandleReCaptchaVerify = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async (e:any) => {
      e.preventDefault()
      if (!executeRecaptcha) {
      
        return;
      }
  
      const token = await executeRecaptcha('contact_post');
   
      //setTokenState(token)
      Submit(token)
      // Do whatever you want with the token
    }, [executeRecaptcha]);
  
    // You can use useEffect to trigger the verification as soon as the component being loaded
    /* useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]); */
  
    return(
       
        <button onClick={handleReCaptchaVerify} className='square-btn'>Skicka</button>
    
     )
  };
  // Checks so required forms are filled
  const CheckForm = () => {
        
    var errorFlag = false

    // eslint-disable-next-line
    Object.keys(formData).map((key:string)=>{
      if(formData[key]){
        
        
        setInputErrorBorder(prev => ({...prev, [key]: "rgba(255, 255, 255, 0.642)"}))        
      }else{
       
        setInputErrorBorder(prev => ({...prev, [key]: "rgb(188, 59, 59)"}))
        errorFlag = true        
      }
    })              
    return errorFlag    
  }

  // Sens formData to the server
  const Submit = (token:string) => {       
    if(CheckForm()){
      setMessage("Formuläret är fel ifyllt")
      return
    }else if (!formData?.approve_storage) {
      setMessage("Du måste acceptare att vi sparar dina uppgifter")        
      return
    }
    setMessage("Skickar Mail...")
    dispatch(postContact({email: formData?.email, phone: formData?.phone, firstName: formData?.firstName, lastName: formData?.lastName, subscription: formData?.subscription, message: formData?.message, recaptchaToken: token}))            
      .then(unwrapResult)
      .then((res:any) => {
      
        if(res?.success){
          setMessage("Tack för ditt mail, jag återkommer med svar strax!")
          setFormData({
            firstName:"",
            lastName:"",
            email:"",
            phone:"",
            message:"",         
            approve_storage: false
          })
        }else if(res?.message){
          setMessage(res?.message)
        }else{
          setMessage("Ett oväntat fel uppstod, ladda om sidan och försök igen")
        }      
      })  
  }
  
  //* UseEffect

  // Changes border color on inputs
  useEffect(() => {
    CheckForm()   
    // eslint-disable-next-line
  }, [formData])

  useEffect(()=> { 
  
    if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1))
    
        if (elem) {
            elem.scrollIntoView({behavior: "smooth"})
        }
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
}, [location,])
  
  //* Gsap/* 
 /*  useLayoutEffect(() => {
		const ctx = gsap.context(()=>{   								
      ScrollTrigger.create({
        trigger: contact_ref.current,
        start: 'top top',
        end: 'bottom top',
        pin: true,
        markers: true,
        pinSpacing: false,
     
      });	        
    },contact_ref)  
    return () => {
      ctx.revert()
    };
  }, []) */
  

  return (
    <section className='container__contact flex__center-c' ref={contact_ref} id='contactform'>     
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_SITE_KEY}>

      {/* Container which is to the right and 80vw */}
      <div className='contact__80vw'>

        {/* Title */}
        <div className='contact__title flex-c'>
          <h1 className='h1__font-large'>{Title_1}</h1>                        
          <p className='p__font-small'>{Info_1}</p>
          <div className='flex' onClick={()=>navigate(`${Link}`)}>
              <p className='p__font-medium' >{Link_Text}</p>
              <IoIosArrowForward id='react-icon-arrow'/>
            </div>
          
        </div>


        {/* Form */}
        <form className='contact__form'>

          {/* First & Lastname */}
          <div className='contact__form-name flex'>
            <input style={{borderColor:inputErrorBorder?.firstName}} type="text" onChange={HandleChange} value={formData?.firstName} placeholder="Förnamn*" name='firstName' required></input>
            <input style={{borderColor:inputErrorBorder?.lastName}} type="text" onChange={HandleChange} value={formData?.lastName} placeholder="Efternamn*" name='lastName' required></input>
          </div>

          {/* Email */}
          <div className='contact__form-email'>
            <input style={{borderColor:inputErrorBorder?.email}} type="text" onChange={HandleChange} value={formData?.email} placeholder="Email*" name='email' required></input>  
          </div>

          {/* Phone */}
          <div className='contact__form-phone flex'>            
            <input type="tel" onChange={HandleChange} value={formData?.phone} placeholder="Telefonnummer" name='phone'></input>  
          </div>  


          {/* Message */}
          <div className='contact__form-msg flex'>
            <textarea onChange={HandleChange} value={formData?.message} placeholder="Meddelande" name='message'></textarea>                          
          </div>

          {/* Subscription checkbox */}
          <div className='contact__form-subscription flex__center-r'>
            <p className='p__font-xsmall'>{Checkbox_1}</p>
            <div className="checkbox-wrapper-19">
              <input 
                type="checkbox"   
                id="cbtest-19" 
                onChange={(e)=>setFormData(prev => ({...prev,
                  [e.target.name]: !formData?.approve_storage
                }))} 
                name="approve_storage" 
                value={formData?.approve_storage}
                
                />
              <label htmlFor="cbtest-19" className="check-box" />
            </div>
          </div>


          {/* Submit Button */}
          <div className='contact__form-submit navbar__navigation-sqrbtn flex__center-c '>             
            <HandleReCaptchaVerify />        
          </div>


          {/* Error message */}
          {message &&
          <div className='flex__center-r'>
            <p className='p__font-small'>{message}</p>
          </div>
          }

        </form>        

                
      </div>
      
      </GoogleReCaptchaProvider>
    </section>
  )
}

export default Contact