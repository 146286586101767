import { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX
import { EditUser } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// ICONS


// SELF IMPORTS

import './SettingsDashboard.css'


const SettingsDashboard = () => {
  type AppDispatch = typeof store.dispatch
  const useAppDispatch = () => useDispatch<AppDispatch>()
  const dispatch = useAppDispatch()

  //* VARIABLES    
  const [email, setEmail] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");  


    //* FUNCTIONS

    // Change password
    const submit = async(e:any) => {    
        e.preventDefault()
        setMessage("laddar...")      
        dispatch(EditUser({email, newPassword, oldPassword}))
            .then(unwrapResult)
            .then((res:any) => {		
              
                if(res?.statusCode === 200){               						                                   
                    setMessage("Lösenordet är bytt")						
                }else{                
                    setMessage("Något gick fel")
                }	
            })    
    }

    

  return (
    <div className='container__settingsdashboard flex__center-c'>

      <form className='settingsdashboard__section flex__center-c'>
        
        <label>Email</label>
        <input type='text' value={email} onChange={(e)=>setEmail(e?.target?.value)} />
        <label>Gamla lösenordet</label>
        <input type='password' value={oldPassword} onChange={(e)=>setOldPassword(e?.target?.value)} />
        <label>Nya lösenordet</label>
        <input type='password' value={newPassword} onChange={(e)=>setNewPassword(e?.target?.value)} />
        <button  onClick={submit}>Byt lösenord</button>
        <h5 className='h5__font-base'>{message}</h5>                                   
      </form>           
      

    </div>
  )
}

export default SettingsDashboard