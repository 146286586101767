import { useLayoutEffect, useRef, useState,useEffect} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import isoWeek from 'dayjs/plugin/isoWeek'
import dayjs from 'dayjs'

// REDUX
import { GetLunch } from '../../slices/tokenReducer'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'


// REACT ICONS
import { IoIosArrowForward,IoIosArrowBack } from 'react-icons/io'

// SELF IMPORTS
import { Loading } from '../../components/index'
import './Lunch.css'


gsap.registerPlugin(ScrollTrigger);
dayjs.extend(isoWeek)


interface lunchinit {[key:string]:string}
const Lunch = ({Title_1,SubTitle_1,Image_1, Error_1, Lunch_Info}:lunchinit) => {
const dispatch: AppDispatch = useDispatch()
  const lunch_ref = useRef(null)

  const GetWeek = (date:number) => {          
    return dayjs(date).isoWeek()          
  }

  //* States
  const [loading, setLoading] = useState(true)
  //const [date, setDate] = useState( Date.now() )
  const [date, setDate] = useState( dayjs().valueOf() )
  const [week, setWeek] = useState( GetWeek(dayjs().valueOf()) )
    


  interface lunchiteminit {[key:string]:string}       
  interface infoinit {[key:string]:lunchiteminit []}                             
  const [information, setInformation] = useState<infoinit>({})
  const [nolunch, setNolunch] = useState<boolean>(false)


  //* Functions

  
  
  const ChangeWeek = (dir:string) => {
    const hour = 3600000
    const day = hour * 24
    const datelimit = Date.now()+day*7*5 
    if(dir === "f" && datelimit > date){      
      setDate(date+day*7)
      setWeek( GetWeek(date+day*7))
    }else if(dir === "b" /* && Date.now() < date */){
        setWeek(  GetWeek(date-day*7) )
      setDate(date-day*7)
    }
    
  }


  //* useeffect
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {       
    setNolunch(false)
    try {
        const func = async() => {
        
            await dispatch(GetLunch({week: week }))
                .unwrap()
                .then((data:any) => {                                                                                        
                    if(data?.statusCode === 200 && typeof data === "object" && data?.data )     {                    
                        setInformation(data?.data)                                                                  
                    }else{
                        setNolunch(true)
                        setInformation({})
                    }
                   
                    
                })  
        }
        func()    
    } catch (error) {
        setNolunch(true) 
    }
  
 /*    fetch(`/menu/lunch_v${GetWeek(date)}.json`)
    .then(response => {    
      
      return response.json();
      }).then(data => {          
        
        setInformation(data);                
      }).catch((e: Error) => {         
    
       
        return
      }); */
  }, [date,week])

  
  
  //* GSAP
  useLayoutEffect(() => {
   
    
    const ctx = gsap.context(()=>{ 
   
      gsap.to(`#lunch_img_1`,{
        yPercent:50,
        scrollTrigger:{
          trigger:lunch_ref.current,
          start:"top top",
          end:"max",
          scrub:true,
          /* markers:true, */
       
        }
      })
 
        
    },lunch_ref)

    return () => {
      
      ctx.revert()
    };

    
  }, [])

  return (
    <section className='container__lunch flex-c' ref={lunch_ref}>     

      {/* Image */}
      <img id="lunch_img_1" className="lunch-img" src={`/${Image_1}`} alt="lunch1" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   

      {/* Lunchmenu */}
    
      <div className='lunch flex__center-c'>
        <h2 className='h2__font-medium'>{Title_1}</h2>
        <h2 className='h2__font-small flex'>
          <IoIosArrowBack id='react-icon-arrow' onClick={()=>ChangeWeek("b")}/> 
          {SubTitle_1}  
          {` `}
          {week}
          <IoIosArrowForward id='react-icon-arrow' onClick={()=>ChangeWeek("f")}/>
        </h2>
        
        {!nolunch ?
        Object?.keys(information)?.map((key:string,j:number)=>{
            const lunchItemArray = information?.[key]
            if(!Array.isArray(lunchItemArray)){
                return
            }
       
          return (
          <div className="lunch__day flex__center-c" key={`object${j}`}>        
            <h2 className='h2__font-small'>{key}</h2>
            {lunchItemArray?.length > 0 && lunchItemArray?.map((item:any,i:number)=> (
              <div className='lunch-item flex__center-r' key={`sallad${i}`}>
                <p className='p__font-small'>{item?.Title}<span>/</span></p> 
                <p className='p__font-small'>{item?.Info} <span>/</span></p>
                <p className='p__font-small'>{item?.Price} </p>
              </div>
              
            ))}
          </div>)
        })
        :
        <div className='lunch-err flex__center-c'>
          <h2 className='h2__font-small'>{Error_1}</h2>
        </div>
        }

        {!nolunch &&
        <div className='lunch-other flex__center-c'>
          <h2 className='h2__font-small'>{}Övrigt</h2>
          <p className='p__font-small'>{Lunch_Info}</p>
        </div>
        }

      </div>
     

      {/* LOADING SCREEN */}
      {loading && 
        <Loading />
      }
    
    </section>
  )
}

export default Lunch