import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { current } from '@reduxjs/toolkit'

import store from '../store'

const serverAddress = process.env.NODE_ENV === "production" ? "https://api.klippanskonstcafe.se" : "http://localhost:5000"


//* Info
export const GetInfo = createAsyncThunk(
    "get/GetInfo",
    async(info: any,{ getState } ) => {
        const res = await axios.get(`${serverAddress}/getinfo/`)        
        return res.data
})


interface infoinit { [key:string]:string|number|boolean }


const FulfilledReducer = (info:infoinit,statusCode:number) =>{            
    if(statusCode === 200){                                
        return info   
    }else{     
        return {}        
    }
}


const initalStateValue = {}


const postSlice = createSlice({
    name:"info",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {        
        //AddMatcher triggers on all /fulfilled in this case
        builder.addCase(GetInfo.fulfilled, (state, action) => { 
         
            return FulfilledReducer(action?.payload?.body?.data, action?.payload?.statusCode)      
        })              
    }
})

const { reducer } = postSlice

export default reducer

 