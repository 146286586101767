import {useEffect, useState,Fragment } from 'react'

//redux
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'
import { GetInfoChangeAble, GetInfo } from '../../slices/tokenReducer';


// SELF imports
import { SmIcons } from '../../components/index'
import {  Header, IntroText, Gallery, Contact} from '../../containers/index'
import './Welcomepage.css'
const Welcomepage = () => {

    const [information, setInformation] = useState<any>({})
    const [information2, setInformation2] = useState<any>({})
    const dispatch: AppDispatch = useDispatch()
    // FETCH info.json
    useEffect(() => {       
        async function func() {
            await dispatch(GetInfo({}))
            .unwrap()
            .then(data => {            
               
                setInformation(data)                       
            })  
            await dispatch(GetInfoChangeAble({}))
            .unwrap()
            .then(data => {            
                setInformation2(data)                       
            })      
        }
        func()
       
    
    }, [])
  return (
    <Fragment>              
        <Header 
            Title_1={information?.Header?.Title_1} 
            Title_2={information?.Header?.Title_2}       
            Message={information2?.Header?.Welcomepage_Message}        
            SubTitle_1={information?.Header?.SubTitle_1} 
            Image={information?.Header?.Image} 

        />
        <IntroText
            Title_1={information?.IntroText?.Title_1} 
            Title_2={information?.IntroText?.Title_2} 
            Info_1={information?.IntroText?.Info_1}               
            Image={information?.IntroText?.Image} 
            Link_Text={information?.IntroText?.Link_Text} 
            Link={information?.IntroText?.Link} 
            Bg={information?.IntroText?.Bg} 
            
            
        />
        <IntroText
            Title_1={information?.IntroText_2?.Title_1} 
            Title_2={information?.IntroText_2?.Title_2} 
            Info_1={information?.IntroText_2?.Info_1}               
            Image={information?.IntroText_2?.Image} 
            Link_Text={information?.IntroText_2?.Link_Text} 
            Link={information?.IntroText_2?.Link} 
            Bg={information?.IntroText_2?.Bg} 
        />
        <IntroText
            Title_1={information?.IntroText_3?.Title_1} 
            Title_2={information?.IntroText_3?.Title_2} 
            Info_1={information?.IntroText_3?.Info_1}               
            Image={information?.IntroText_3?.Image} 
            Link_Text={information?.IntroText_3?.Link_Text} 
            Link={information?.IntroText_3?.Link} 
            Bg={information?.IntroText_3?.Bg} 
        />
        <IntroText
            Title_1={information?.IntroText_4?.Title_1} 
            Title_2={information?.IntroText_4?.Title_2} 
            Info_1={information?.IntroText_4?.Info_1}               
            Image={information?.IntroText_4?.Image} 
            Link_Text={information?.IntroText_4?.Link_Text} 
            Link={information?.IntroText_4?.Link} 
            Bg={information?.IntroText_4?.Bg} 
        />
        <Gallery
            Title_1={information?.Gallery?.Title_1} 
            SubTitle_1={information?.Gallery?.SubTitle_1} 
            Link_Instagram={information?.Gallery?.Link_Instagram} 
        />
        
        <Contact
        
            Title_1={information?.Contact?.Title_1} 
            Info_1={information?.Contact?.Info_1} 
            Checkbox_1={information?.Contact?.Checkbox_1} 
            Link_Text={information?.Contact?.Link_Text} 
            Link={information?.Contact?.Link} 
        />                                
        <SmIcons 
            Link_Instagram={information2?.Information?.Link_Instagram} 
            Link_Facebook={information2?.Information?.Link_Facebook} 
            Link_Map={information2?.Information?.Link_Map} 
        />
        
    </Fragment>
  )
}

export default Welcomepage