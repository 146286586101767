import { useState, useLayoutEffect, useRef }from 'react'
import { useNavigate } from 'react-router-dom'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from "react-scroll";






// REACT ICONS
import { GiKnifeFork } from 'react-icons/gi'


// SELF IMPORTS 
import { ReactComponent as YourSvg } from '../../svg/klippan.svg'
import './Navbar.css'

// STYLES
const mobileStyleInit_ON = {pointerEvents:"auto",opacity:1,right:"0",overflow:"hidden",height:"100%"}
const mobileStyleInit_OFF = {pointerEvents:"none",opacity:0,right:"-1000px"}



gsap.registerPlugin(ScrollTrigger);
const Navbar = () => {
  const navigate = useNavigate()


  const navbar_ref = useRef(null)

  //* INTERFACE
 

  //* VARIABLES
  const [toggleNavbarMenu, setToggleNavbarMenu] = useState<boolean>(false)
  const [mobileNavbarStyle, setMobileNavbarStyle] = useState<any>(mobileStyleInit_OFF)
 
  const [spanStyle, setSpanStyle] = useState({span1:{},span2:{},span3:{}})

  
  //* FUNCTIONS
  const navigateClick = (location=""):any => {
    if(location){      
      navigate(location);      
    }
    setMobileNavbarStyle({pointerEvents:"none",opacity:0,right:"-1000px"})   
    setSpanStyle(prev => ({...prev,
      span1:{},
      span2:{},
      span3:{}

    })) 
    
    setToggleNavbarMenu(false)
  }
  const ToggleMobileMenu = (toggle:boolean) => {
    if(toggle){
      setMobileNavbarStyle(mobileStyleInit_OFF)   
      setSpanStyle(prev => ({...prev,
        span1:{},
        span2:{},
        span3:{}

      })) 
          
    }else{
      setMobileNavbarStyle(mobileStyleInit_ON)   
      setSpanStyle(prev => ({...prev,
        span1:{transform: "rotate(45deg) translate(-3px, -1px)"},
        span2:{opacity: 0, transform: "rotate(0deg) scale(0.2, 0.2)" },
        span3:{transform: "rotate(-45deg) translate(0, -1px)" }

      })) 
    }
    setToggleNavbarMenu(!toggle)
  }

  //* UseEffect

 
  //* STYLE


  //* GSAP+

  // Fades the navbar when scrolling down and shows when scrolling up
/*   useLayoutEffect(() => {                
    const ctx = gsap.context(()=>{
        
        const showNav = gsap.fromTo(navbar_ref.current,{
        autoAlpha: 0
        },{
        autoAlpha: 1,
        duration:0.4
        }).progress(1)

            
        
        ScrollTrigger.create({                   
            start: "top top", 
            end: "max",                  
            onUpdate: (self) => {
                console.log("toggleNavbarMenu",toggleNavbarMenu)
            if(toggleNavbarMenu) {
                console.log("hello1")
                return
            }else{
                console.log("hello2")
                self.direction === -1 ? showNav.play() : showNav.reverse()
            }
            
            }
        })                      
    },navbar_ref)



    return () => {
        ScrollTrigger.refresh()
        ctx.revert()
    };
        
  }, []) */
  

  return (
    <div className='component__navbar flex__center-r' ref={navbar_ref} >

      
      {/* TITLE BUTTON */}
      <div className='navbar__icon  main-icon' onClick={()=>navigate("/")}>
        {/* <h1 className='h1__font-medium' onClick={()=>navigate("/")}>Sockerbrukets Bistro</h1> */}
        {/* <GiKnifeFork id='react-main-icon'/> */}
        {/* <img className="klippan__logo" src={`/images/Union.svg`} alt='logo'/> */}
        <div className='navbar__navigation-buttons flex__center-c' >
          {/* <button className='p__font-medium'  onClick={()=>navigate("/")}>Hem</button> */}
            <img style={{width:"4rem",height:"4rem"}} src={`/images/mainlogo.webp`} alt='logo'/>
        </div>
      </div>


      {/* MIDDLE NAVIGATION MENU */}
      <div className='navbar__navigation flex__center-r'>
     
        <div className='navbar__navigation-buttons flex__center-c'>
          <button className='p__font-medium' onClick={()=>navigate("/menu")}>Meny</button>
          <div className='navbar-line'></div>
        </div>

        <div className='navbar__navigation-buttons  flex__center-c'>
          <button className='p__font-medium' onClick={()=>navigate("/lunch")}>Lunchmeny</button>
          <div className='navbar-line'></div>
        </div>

        <div className='navbar__navigation-buttons  flex__center-c'>
          <button className='p__font-medium' onClick={()=>navigate("/event")}>Event</button>
          <div className='navbar-line'></div>
        </div>         

        <div className='navbar__navigation-buttons  flex__center-c'>
          <button className='p__font-medium' onClick={()=>navigate("/contact")}>Kontakt</button>
          <div className='navbar-line'></div>
        </div>     

        <div className='navbar__navigation-buttons  flex__center-c'>
          <button className='p__font-medium' onClick={()=>navigate("/icecream")}>Gelato</button>
          <div className='navbar-line'></div>
        </div>     

        <div className='navbar__navigation-buttons  flex__center-c'>
          <button className='p__font-medium' onClick={()=>navigate("/gallery")}>Galleri</button>
          <div className='navbar-line'></div>
        </div>     

        <div className='navbar__navigation-buttons  flex__center-c'>
          <button className='p__font-medium' onClick={()=>navigate("/#footer")}>Hitta oss</button>
          <div className='navbar-line'></div>
        </div>     

        <div className='navbar__navigation-sqrbtn flex__center-c'>
          <button className='p__font-medium square-btn' onClick={()=>navigate("/booking")}>Boka bord</button>
        </div>

      </div>


                 

      {/* MOBILE NAVBAR */}
      <div className='navbar-small__icon' onClick={()=>ToggleMobileMenu(toggleNavbarMenu)} >
          <span style={spanStyle.span1}></span>
          <span style={spanStyle.span2}></span>
          <span style={spanStyle.span3}></span>               
      </div>      
      <div className='navbar-small flex__center-c' style={mobileNavbarStyle}>                                
        <div className='navbar__icon' >
          <Link onClick={()=>{navigateClick("/")}} className="footer__information__icon-link" to="container__header" spy={true} smooth={true}>
            {/* <h1 className='h1__font-medium' onClick={()=>navigate("/")}>Sockerbruket</h1> */}
            <img style={{width:"4rem",height:"4rem"}} src={`/images/mainlogo_white.webp`} alt='logo'/>
          </Link>
        </div>
        <button className='p__font-medium no-border' onClick={()=>navigateClick("/menu")}>Meny</button>
        <button className='p__font-medium no-border' onClick={()=>navigateClick("/lunch")}>Lunchmeny</button>
        <button className='p__font-medium no-border' onClick={()=>navigateClick("/event")}>Event</button>
        <button className='p__font-medium no-border' onClick={()=>navigateClick("/contact")}>Kontakt</button>        
        <button className='p__font-medium no-border' onClick={()=>navigateClick("/icecream")}>Gelato</button>        
        <button className='p__font-medium no-border' onClick={()=>navigateClick("/gallery")}>Galleri</button>        
        <button className='p__font-medium no-border' onClick={()=>navigateClick("/#footer")}>Hitta oss</button>        
        <button className='p__font-medium square-btn' onClick={()=>navigateClick("/booking")}>Boka tid eller Frågor?</button>                                      
      </div>
      
    </div>
  )
}

export default Navbar