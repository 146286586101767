import React,{ useLayoutEffect, useRef, useState,useEffect} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// REDUX
import { GetMenu } from '../../slices/tokenReducer'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'


// REACT ICONS


// SELF IMPORTS
import { Loading } from '../../components/index'
import './Menu.css'


gsap.registerPlugin(ScrollTrigger);



interface menuinit {[key:string]:string}
const Menu = ({Image_1,Image_2,Image_3,Image_4,Menu_Category_1,Menu_Category_2,Menu_Category_3,Menu_Category_4}:menuinit) => {

  const menu_ref = useRef(null)
  const dispatch: AppDispatch = useDispatch()
 

  //* States
  const [loading, setLoading] = useState(true)
  interface menuiteminit {[key:string]:string}       
  interface infoinit {
    "Sallader&smörgåsar": menuiteminit []
    "a_la_carte": menuiteminit []
    "Pizza": menuiteminit []
    "Dryck": {
      Drycker : menuiteminit []
      Alkohol: menuiteminit []
      Viner: menuiteminit []
      Alkoholfritt: menuiteminit []

    }
      
    
}
  const [information, setInformation] = useState<infoinit>({
    "Sallader&smörgåsar": [],
    "a_la_carte":  [],
    "Pizza":  [],
    "Dryck": {
      Drycker: [],
      Alkohol: [],
      Viner: [],
      Alkoholfritt: [],
    }
    })

  //* useeffect
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {       

    dispatch(GetMenu({}))
    .unwrap()
    .then(data => {            
        setInformation(data)                       
    })  
 /*    fetch(`/menu/menu.json`)
    .then(response => {    
      return response.json();
      }).then(data => {          
        setInformation(data);                
      }).catch((e: Error) => {         
        return
      }); */
  }, [])

  

  //* GSAP
  useLayoutEffect(() => {
   
    const ctx = gsap.context(()=>{ 
     
      gsap.to(`#menu_img_1`,{
        yPercent:50,
        scrollTrigger:{
          trigger:menu_ref.current,
          start:"top top",
          end:"bottom bottom",
          scrub:true,
         /*  markers:true, */
       
        }
      })
 
        
    },menu_ref)

    return () => {
     
      ScrollTrigger.refresh()
      ctx.revert()
    };

    
  }, [])

  return (
    <section className='container__menu flex-c' ref={menu_ref}>     

      {/* Image */}
      <img id="menu_img_1" className="menu-img" src={`/${Image_1}`} alt="menu1" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   

      {/* Sallad - smörgåsar */}
      <div className='menu__salad flex__center-c'>
        <h2 className='h2__font-small'>{Menu_Category_1}</h2>
        {information?.["Sallader&smörgåsar"]?.map((item:any,i:number)=> (
          <div className='menu-item flex__center-r' key={`sallad${i}`}>
            <p className='p__font-small'>{item?.Title}<span>-</span></p> 
            <p className='p__font-small'>{item?.Info} <span>-</span></p>
            <p className='p__font-small'>{item?.Price} </p>
          </div>
          
        ))}
      </div>

      {/* Image 2 sticky */}
      <img id="menu_img_2" className="menu-img-sticky" src={`/${Image_2}`} alt="menu1" />   

      {/* a la carte */}
      <div className='menu__alacarte flex__center-c'>
      <h2 className='h2__font-small'>{Menu_Category_2}</h2>
      {information?.["a_la_carte"]?.map((item:any,i:number)=> (
          <div className='menu-item flex__center-r' key={`a_la_carte${i}`}>
            <p className='p__font-small'>{item?.Title}<span>-</span></p> 
            <p className='p__font-small'>{item?.Info} <span>-</span></p>
            <p className='p__font-small'>{item?.Price} </p>
          </div>
          
        ))}
        
      </div>


      {/* Image 3 sticky */}
      <img id="menu_img_2" className="menu-img-small" src={`/${Image_3}`} alt="menu1" />   

      {/* Pizzas */}
      <div className='menu__pizza flex__center-c'>
        <h2 className='h2__font-small'>{Menu_Category_3}</h2>
        {information?.["Pizza"]?.map((item:any,i:number)=> (
          <div className='menu-item flex__center-r' key={`Pizza${i}`}> 
            <p className='p__font-small'>{item?.Title}<span>-</span></p> 
            <p className='p__font-small'>{item?.Info} <span>-</span></p>
            <p className='p__font-small'>{item?.Price} </p>
          </div>
          
        ))}
      </div>



      {/* Image 4 regular */}      
      {/* Drinks */}     
      <div className='menu__drinks flex__center-c'>
        {/* <img id="menu_img_2" className="menu-img-sticky" src={`/${Image_4}`} alt="menu1" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   */}
        <div className='menu__drinks-container flex__center-c'>
          <h2 className='h2__font-small'>{Menu_Category_4}</h2>
        
         <div className='menu__drinks-container-columns flex'>
          
          <div className='menu-doublecolumn flex' style={{justifyContent:"space-around"}}>

          <div className='menu__drinks-container-column flex-c'>              
            <p className='p__font-small'>Drycker</p>   
            {information?.["Dryck"]?.["Drycker"]?.map((item:any,i:number)=> (
              <div className='drink-item' key={`Dryck_cat${i}`}>
                <p className='p__font-xsmall'>{item?.Title}</p>
                {item?.Price !== 0 && <p className='p__font-xsmall'>{item?.Price}</p> }
                <p className='p__font-xsmall'>{item?.Info}</p>
              </div>              
            ))}    
          </div>

          <div className='menu__drinks-container-column flex-c'>              
            <p className='p__font-small'>Alkohol</p>   
            {information?.["Dryck"]?.["Alkohol"]?.map((item:any,i:number)=> (
              <div className='drink-item' key={`Dryck_cat${i}`}>
                <p className='p__font-xsmall'>{item?.Title}</p>
                {item?.Price !== 0 && <p className='p__font-xsmall'>{item?.Price}</p> }
                <p className='p__font-xsmall'>{item?.Info}</p>
              </div>              
            ))}    
          </div>

          </div>
          <div className='menu-doublecolumn flex' style={{justifyContent:"space-around"}}>

          <div className='menu__drinks-container-column flex-c'>              
            <p className='p__font-small'>Viner</p>   
            {information?.["Dryck"]?.["Viner"]?.map((item:any,i:number)=> (
              <div className='drink-item' key={`Dryck_cat${i}`}>
                <p className='p__font-xsmall'>{item?.Title}</p>
                {item?.Price !== 0 && <p className='p__font-xsmall'>{item?.Price}</p> }
                <p className='p__font-xsmall'>{item?.Info}</p>
              </div>              
            ))}    
          </div>
                                      
            <div className='menu__drinks-container-column flex-c'>              
              <p className='p__font-small'>Alkoholfritt</p>          
              {information?.["Dryck"]?.["Alkoholfritt"]?.map((item:any,i:number)=> (
                <div className='drink-item' key={`Dryck_cat${i}`}>
                  <p className='p__font-xsmall'>{item?.Title}</p>
                  {item?.Price !== 0 && <p className='p__font-xsmall'>{item?.Price}</p> }
                  <p className='p__font-xsmall'>{item?.Info}</p>
                </div>              
              ))}    
            </div>
          
       
            </div>

     
        
          </div>
          </div>
      </div>  


      {/* LOADING SCREEN */}
      {loading && 
        <Loading />
      }
    
    </section>
  )
}

export default Menu