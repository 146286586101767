import React ,{ useEffect, useState } from 'react'

//redux
import { GetIcecream } from '../../slices/tokenReducer'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'


// Swiper

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,EffectCoverflow, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


// Self imports
import './Icecream.css'

interface inputinit {[key:string]:string}
const Icecream = ({Title_1,IntroText_1}:inputinit) => {

    // VARIBLES
    const [images, setImages] = useState<any []>([])
    const [newimages, setNewImages] = useState<any []>([])
    const dispatch: AppDispatch = useDispatch()
    // useEffect
    // sets images to usestate
    useEffect(() => {  
        dispatch(GetIcecream({}))
        .unwrap()
        .then(data => {            
            const tempArr:any = []
                const newtempArr:any = []
                
                for (let i = 0; i < data?.Icecream?.length; i++) {
                    
                    let imageObj =  data?.Icecream?.[i];
                    let image = imageObj?.Image
                    
                    tempArr.push(imageObj)
                    newtempArr.push(`/${image}`)
                }
                
                
                setImages(tempArr)    
                setNewImages(newtempArr)                      
        })  
        
      /*   fetch(`/icecream/icecream.json`)
        .then(response => {
            
            return response.json();
            }).then(data => {                               
                const tempArr:any = []
                const newtempArr:any = []
                
                for (let i = 0; i < data?.Icecream?.length; i++) {
                    
                    let imageObj =  data?.Icecream?.[i];
                    let image = imageObj?.Image
                    
                    tempArr.push(imageObj)
                    newtempArr.push(`/${image}`)
                }
                
                
                setImages(tempArr)    
                setNewImages(newtempArr)
            

            }).catch((e: Error) => {         
            return
            }); */
            //eslint-disable-next-line
    }, [])



    return (
        <section className='container__icecream flex__center-c'>

        <h2 className='h2__font-medium'>{Title_1}</h2>
        <p className='p__font-medium'>{IntroText_1}</p>

            {/* Image Carusel */}    
        {   newimages?.length > 0 && 
            <Swiper 
                className='flex__center-r'
            /*   onClick={(e:any)=> window.location.href = `${Link_Instagram}`   } */
                modules={[Autoplay,EffectCoverflow, Pagination]} 
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                pagination={true}
                
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}                   
                autoplay={{
                    delay: 100000,
                    disableOnInteraction: false
                }}
                loop={true}
                spaceBetween={0}                   
            >        
            {images.map((obj,i) => {        
            return (
                
                <SwiperSlide key={i} >
                    <div className='icecream__card flex__center-c'>
                        <img className='swiper-img' src={`/${obj.Image}`}  alt='test'/>

                        <div className='icecream__card-line flex__center-c'>
                            <div className='icecream__card-line-circle flex__center-c'>
                               {/*  <img className="klippan__logo" src={`/images/Union1.svg`} alt='logo'/> */}
                            </div>
                        </div>

                        <div className='icecream__card-info flex_center-c'>
                            <h2 className='h2__font-small'>{obj.Title}</h2>
                            <p className='p__font-small'>{obj.Intro}</p>
                        </div>
                    </div>
                </SwiperSlide>)
            })}
            </Swiper>    
        }

        </section>
    )
}

export default Icecream