import { useState, useEffect, FormEvent } from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import FileBase from 'react-file-base64'


// REDUX
import { AddEvent, DeleteEvent ,GetEvent} from '../../slices/tokenReducer'; 
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'



// ICONS

// SELF IMPORTS 
import './EventDashboard.css'

const EventDashboard = () => {
    const dispatch: AppDispatch = useDispatch()



	//* Interface 


	//* VARIABLES
	const [message, setMessage] = useState<string>("")
   
    interface imageinit {    
        base64: string
        size: string
        name: string
        type: string    
    }
    const [images, setImages] = useState<imageinit []>([])
    interface informationinit {[key:string]:eventinit}
    interface newinformationinit {
        Title: string
        Info: string
    }
    interface eventinit {    
        Title: string
        Info: string
        Images: string []        
    }
    const [information, setInformation] = useState<informationinit>({})
    const [newInformation, setNewInformation] = useState<newinformationinit>({
        Title: "",
        Info: ""
    })





    //* Functions
    // Changes inputs/textarea for new item
    const handleChange = (e:any) => {
        const { name, value } = e?.target
        setNewInformation(prev => ({...prev, [name]: value}))
    }

    // Recursive function for Uploading blog information
    const dispatchEventItem = async(inputData:any, index:number, lastCall:number) => {     
        let startValue = index    
        var returndata              
        let dispatchData
        let img = images?.[index] || undefined
 
        if(index === 0){
            dispatchData = {newInformation: newInformation, information: information, NewImage: img, lastCall,  Index:startValue,   }
        }else{
            dispatchData = {information: inputData?.data, _id:inputData?._id, NewImage: img, lastCall,Index:startValue, }
        }
       
        await dispatch(AddEvent(dispatchData))
            .then(unwrapResult)
            .then(async (res:any) => {
                if(res?.statusCode === 200){               						                                                       
                        
                            
                    returndata = {data: res?.body?.data, _id: res?.body?._id, statusCode: res?.statusCode}
                    
                }else{                
                    setMessage("Något gick fel")
                    returndata =  {statusCode: 400}
                    
                }
                
            }).catch(error => {
                setMessage("Något gick fel")
                returndata =  {statusCode: 400}
            })
            
        return returndata
        
    }
      
	// Uploads new blog item
	const UploadNewEvent = async(event: FormEvent<HTMLFormElement>) =>{    
        event.preventDefault()
		setMessage("laddar...") 

        if(!newInformation){
            setMessage("Fyll i fälten korrekt")
            return
        }
		

		let inputData:any = {}
		let lastCall = images.length 
        //console.log("lastCall",lastCall)
        let imageLength = images.length > 0 ? images.length : 1
		for(let i = 0; i<imageLength; i++){        
			const returnData = await dispatchEventItem(inputData,i,lastCall)                
			inputData = returnData
		}
		if(inputData.statusCode === 200){
			setMessage("Eventet är uppladdat!")
		}
		
	}

    // Delete blog permanent item
    const DeleteEventItem = async(_id:string) =>{    
        setMessage("laddar...")                 
            dispatch(DeleteEvent({_id: _id, oldInformation: information, }))
                .then(unwrapResult)
                .then((res:any) => {                                                  
                    if(res?.statusCode === 200){               						                                   
                        setMessage("Eventet är bortaget")
                        
                    }else{                
                        setMessage("Något gick fel")
                    }
                })        
                    
    }


 

    const handleImageUpload = (file:any) => {
      
        setImages(prev=>[...prev,file])

    } 

    // Fetches image _ids from gallery
    useEffect(() => {       
        dispatch(GetEvent({}))
        .unwrap()
        .then(data => {          
           
            setInformation(data)                       
        })  
    }, [])

   
 
	return (
		<div className='container__eventdashboard flex__center-c'>
		

		
		
			<form className='eventdashboard__upload flex__center-c' onSubmit={UploadNewEvent}>
			

				<h1 className='h1__font-medium'>Event</h1>          
				<button type='submit' >Ladda upp</button>            
				<p>{message}</p>          

				<label>Title</label>              
				<textarea required value={newInformation?.Title} name="Title" onChange={handleChange}></textarea>        

				<label>Info text</label>              
				<textarea required value={newInformation?.Info} name="Info" onChange={handleChange}></textarea> 

				<div className='image__uploads flex__center-c'>              
					<FileBase 
						type="file"
						accept='.webp' 
						id="fcv" 
						multiple={false}                       
						onDone={(base64:any) => {handleImageUpload(base64)} }
						name={`serviceimage`}            
						required
						>
					</ FileBase>
					<button onClick={()=>setImages([])}>Reset</button>                
					{images.map((imageFile:imageinit,i:number)=>(
						<p className='p__font-xsmall' key={`imageFile${i}`}>{imageFile?.name}</p>
					))}
						
				</div>

				
		



			</form>

			<div className='eventdashboard__list flex__center-c'>            
				{Object.keys(information).map((key,i:number)=>{
					const eventItem = information[key];
					return (
						<div className='eventdashboard__list__item flex-c' key={`eventdashboard__list${i}`}>                        
							<p className='p__font-small'>Title: {eventItem?.Title}</p>
							<p className='p__font-small'>Info: {eventItem?.Info}</p>                        
							<div className='eventdashboard__list__item-images flex'>
								{eventItem?.Images.map((image,i:number)=>(
									<div className='eventdashboard__list__item-img flex' key={`eventdashboard__list-img${i}`}>
										<img  src={`/${image}`} alt='event'/>                                
									</div>
								))}
							</div>
							<button onClick={()=>DeleteEventItem(key)}>Ta bort event</button>
						</div>
				)})}
			
			</div>
		
			
		

		</div>
	)
}

export default EventDashboard