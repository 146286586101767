import {useEffect, useState} from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX

import { UpdateMenu, GetMenu } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// ICONS
import { ImArrowDown, ImArrowUp } from 'react-icons/im'

// SELF IMPORTS
import './MenuDashboard.css'
const MenuDashboard = () => {
	type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
  
	//* VARIABLES
	const [information, setInformation] = useState<any>({})	
	const [message, setMessage] = useState<string>("")		
	

	//* Functions
	const Submit = async() => {
        setMessage("laddar...") 
        
		dispatch(UpdateMenu({information, }))
			.then(unwrapResult)
			.then((res:any) => {                                                  
				if(res?.statusCode === 200){               						                                   
					setMessage("Menyn är uppladdade!")
					                                            
				}else{                
					setMessage("Något gick fel")
				}
			})        
               
    }  
	// Changes the value of input/textarea of NON drink meny
	const HandleChange = (e:any,category:string,j:number) => {
		const { name, value } = e.target		
		let tempInfo = information?.[category]
		tempInfo[j][name] = value			
		setInformation((prev:any)=>({...prev,[category]:tempInfo}))
	}
	// Changes the value of input/textarea of Drink meny
	const HandleChangeDrinks = (e:any,category:string,j:number) => {
		const { name, value } = e.target	
		let tempInfo = information?.Dryck		
		tempInfo[category][j][name] = value		
		setInformation((prev:any)=>({...prev,Dryck:tempInfo}))
	}
	
	// Change the order of items with button clicks instead of drag and drop
    const ChangeOrder = (dir:string,j:number,category:string) =>{    
		const chosenArray = information?.[category]
        if( (dir === "down" && j === chosenArray.length - 1) || (dir === "up" && j === 0) ){
        	return
        }
        var tempArr:any = chosenArray
        var value = chosenArray[j]
        tempArr.splice(j,1)
        if(dir==="down"){
        	tempArr.splice(j+1,0,value)
        }else{
       		tempArr.splice(j-1,0,value)
        }        
        setInformation((prev:any)=>({...prev, [category]:[...tempArr] }))
    }
	// Change the order for the drinks
	const ChangeOrderDrinks = (dir:string,j:number,category:string) =>{    
		var chosenArray = information?.Dryck?.[category]
	
		let chosenObject = information?.Dryck
        if( (dir === "down" && j === chosenArray.length - 1) || (dir === "up" && j === 0) ){
        	return
        }
        var tempArr:any = chosenArray
        var value = chosenArray[j]
        tempArr.splice(j,1)
        if(dir==="down"){
        	tempArr.splice(j+1,0,value)
        }else{
       		tempArr.splice(j-1,0,value)
        }
		
		
		chosenObject[category] = tempArr
	


		setInformation((prev:any)=>({...prev, Dryck: chosenObject}))
    }
	
	
	// Adds Empty object to menu list
    const AddEmptyToMenu = (category:string) =>{    
		var tempArr = information?.[category]              
       	tempArr.unshift({Title:"",Price:0,Info:""})        
        setInformation((prev:any)=>({...prev, [category]:[...tempArr] }))		
    }
	// Removes chosen menu item
	const RemoveItemOnMenu = (i:number,category:string) =>{
		var tempArr = information?.[category]
		tempArr.splice(i,1)
		setInformation((prev:any)=>({...prev, [category]:[...tempArr] }))		
	}



	// Adds empty drink item to drinks list
    const AddEmptyToDrink = (category:string) =>{    
		var tempArr = information?.Dryck
       	tempArr?.[category].unshift({Title:"",Price:0,Info:""})        
        setInformation((prev:any)=>({...prev, Dryck: tempArr }))
    }
	// Removes chosen drink item
	const RemoveItemOnDrink = (i:number,category:string) =>{
		var tempArr = information?.Dryck
		tempArr?.[category].splice(i,1)
		setInformation((prev:any)=>({...prev, Dryck: tempArr }))			
	}

	//* useEffect
    // Gets info from s3
    useEffect(() => {       
        dispatch(GetMenu({}))
        .unwrap()
        .then(data => {            
            setInformation(data)                       
        })  

     /*    fetch(`/menu/menu.json`)
        .then(response => {
     
        return response.json();
        }).then(data => {          
            setInformation(data);                
        }).catch((e: Error) => {         
            return
        }); */
    }, [])
	

	return (
		<div className='container__menudashboard flex__center-c'>

		<h1 className='h1__font-medium'>Meny</h1>

		<button onClick={Submit}>Uppdatera meny</button>
		<p>{message}</p>

		{Object?.keys(information).map((category,i)=>{
			const element = information[category]
			
			if(Array.isArray(element) ){
				return (
					<div className='menudashboard__category flex__center-c' key={`menudashboard__category${i}`}>
						<h1 className='h2__font-large'>{category}</h1>
						<button onClick={()=>AddEmptyToMenu(category)}>Lägg till en ny</button>
						{element?.map((item,j)=>(
							<div className='menudashboard__category-item flex__center-c'  key={`menudashboard__category-item${j}`}>
								<div><ImArrowDown className="ImArrow" onClick={()=>ChangeOrder("down",j,category)}/> <ImArrowUp className="ImArrow" onClick={()=>ChangeOrder("up",j,category)}/></div>
								<button onClick={()=>RemoveItemOnMenu(j,category)}>Ta bort {item?.Title}</button>
								<label>Title</label>
								<input type='text' value={information?.[category]?.[j]?.Title} onChange={(e)=>HandleChange(e,category,j)} name={'Title'}></input>								
								<label>Info</label>
								<textarea  value={information?.[category]?.[j]?.Info} onChange={(e)=>HandleChange(e,category,j)} name={'Info'}></textarea>
								<label>Pris</label>
								<input  type='number' value={information?.[category]?.[j]?.Price} onChange={(e)=>HandleChange(e,category,j)} name={'Price'} ></input>
							</div>
						))}
					</div>
				)
			}else{
				return(
					<div className='menudashboard__drinks flex__center-c' key={`menudashboard__drinks${i}`}>
					<h1 className='h2__font-large'>{category}</h1>
					{Object.keys(information[category]).map((drinkCategoryKey,j)=>(							
						<div className='menudashboard__drinks__category flex__center-c' key={`item${i}${j}`}>
							<h1 className='h2__font-medium'>{drinkCategoryKey}</h1>
							<button onClick={()=>AddEmptyToDrink(drinkCategoryKey)}>Lägg till en ny dricka</button>							
							{element?.[drinkCategoryKey].map((drinkKey:any,k:number)=>(
							<div className='menudashboard__drinks__category-item flex__center-c' key={`drink${k}`}>
								<div><ImArrowDown className="ImArrow" onClick={()=>ChangeOrderDrinks("down",k,drinkCategoryKey)}/> <ImArrowUp className="ImArrow" onClick={()=>ChangeOrderDrinks("up",k,drinkCategoryKey)}/></div>									
								<button onClick={()=>RemoveItemOnDrink(k,drinkCategoryKey)}>Ta bort {drinkKey?.Title}</button>
								<label>Title</label>
								<input type='text' value={information?.[category]?.[drinkCategoryKey]?.[k].Title} name={'Title'} onChange={(e)=>HandleChangeDrinks(e,drinkCategoryKey,k)}></input>
								<label>Info</label>
								<textarea value={information?.[category]?.[drinkCategoryKey]?.[k].Info} name={'Info'} onChange={(e)=>HandleChangeDrinks(e,drinkCategoryKey,k)}></textarea>
								<label>Pris</label>
								<input type='number' value={information?.[category]?.[drinkCategoryKey]?.[k].Price} name={'Price'} onChange={(e)=>HandleChangeDrinks(e,drinkCategoryKey,k)}></input>
								
							</div>))}
						</div>
					))}
					</div>
				)
			}	
		})}		
	</div>
	)
}

export default MenuDashboard