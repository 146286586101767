import { useState, useEffect} from 'react';

//redux
import { GetGallery } from '../../slices/tokenReducer'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'



import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade,Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';


// SELF IMPORTS
import './Gallery.css';



interface initInfo {
  Title_1: string
  SubTitle_1: string
  Link_Instagram: string  
}
const tempInfo = [
    {
        key: 'gallery/9ea3dd91-a4af-4ee0-a33d-b580bc699931.webp',
        text: "asd"
    },
    {
        key: 'gallery/8312c218-ae56-4701-b3f5-ddb2737a857a.webp',
        text: "test text text text "
    },
    {
        key: 'gallery/aea0ab32-7dbb-4108-a8b8-9dab9accf95e.webp',
        text: "test text text text "
    },
    {
        key: 'gallery/c30e3929-70c4-4688-a31b-a20dd084a076.webp',
        text: "test text text text "
    },
    {
        key: 'gallery/9ea3dd91-a4af-4ee0-a33d-b580bc699931.webp',
        text: "test text text text "
    },
    {
        key: 'gallery/9ea3dd91-a4af-4ee0-a33d-b580bc699931.webp',
        text: "test text text text "
    },
    {
        key: 'gallery/9ea3dd91-a4af-4ee0-a33d-b580bc699931.webp',
        text: "test text text text "
    },
    {
        key: 'gallery/9ea3dd91-a4af-4ee0-a33d-b580bc699931.webp',
        text: "test text text text "
    }
]
const Gallery = ({Title_1,SubTitle_1,Link_Instagram}: initInfo) => {

  
  // VARIBLES
  interface Iimages {
    key:string
    text:string
  }
  const [images, setImages] = useState<Iimages []>([])

  const dispatch: AppDispatch = useDispatch()
  // sets images to usestate
  useEffect(() => {       

    const func = async() => {
        
    await dispatch(GetGallery({}))
    .unwrap()
    .then(res => {                   
       setImages(res?.Gallery)

       

    })
  
    }
    func()
  /*   fetch(`/gallery/gallery.json`)
    .then(response => {
      
      return response.json();
      }).then(data => {                               
        const tempArr:any = []
        const newtempArr:any = []
        
        for (let i = 0; i < data?.Gallery?.length; i++) {
         
          let image =  data?.Gallery?.[i];
         
          tempArr.push({original: `/${image}`})
          newtempArr.push(`/${image}`)
        }
        
        setImages(tempArr)    
        setNewImages(newtempArr)
       

      }).catch((e: Error) => {         
        return
      }); */
       //eslint-disable-next-line
  }, [])





  
  if(images?.length > 0) {
  return(
    <section className='container__gallery flex__center-c' >      
      
      
      <h1 className='h1__font-medium'>{Title_1}</h1>
      <p className='p__font-small'>{SubTitle_1}</p>

      
     
        <Swiper 
          className='flex__center-r'
          onClick={(e:any)=> window.location.href = `${Link_Instagram}`   }
          modules={[Autoplay,EffectFade]} 
          effect={"fade"}          
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          loop={true}
          spaceBetween={30}                   
          >        
        {images.map((file,i) => {        
          return <SwiperSlide key={`galleryimages${file?.key}${i}`}><img className='swiper-img' src={`/${file?.key}`}  alt=''/></SwiperSlide>;
        })}
        </Swiper>    
      
     
      
    </section>
  )}else{return(<div style={{display:"none"}}></div>)}
}
export default Gallery;
