import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// React icons
import { IoIosArrowForward } from 'react-icons/io'
import { AiFillInstagram,AiFillFacebook } from 'react-icons/ai'


// Self imports
import './Footer.css'

interface footerinit{[key:string]:string}

gsap.registerPlugin(ScrollTrigger);
const Footer = ({Title_1,Title_2,Title_3,Title_4,Link_Text,Link_Instagram,Link_Facebook,Link_Map,Times,Email,Phone_1,Phone_2,Address,Company_Name,Image}:footerinit) => {





	//* Gsap
	/* useLayoutEffect(() => {
		const ctx = gsap.context(()=>{   

								
				ScrollTrigger.create({         
					trigger: footer_ref.current,       
					start: "top bottom",										
					end: "bottom bottom",
					pin:true,			
					markers:true
				})
			
      
  
    },footer_ref)
  
    return () => {
      ctx.revert()
    };
  }, []) */


    return (
			<div className='component__footer flex-c' id="footer">

				<img className="" src={`/${Image}`} alt='footer'></img>				
				<div className='container__80vw flex-c'>

					<div className='footer__time'>
						<h2 className='h2__font-medium'>{Title_3}</h2>
						<p className='p__font-medium'>{Times}</p>
					</div>

					<div className='footer__info flex'>
						<div className='footer__info-left'>					
							<h2 className='h2__font-medium'>{Title_1}</h2>
							<p className='p__font-medium'>{Email}</p>
							<p className='p__font-medium'>{Phone_1}</p>
							<p className='p__font-medium'>{Phone_2}</p>							
						</div>
						<div className='footer__info-right'>
							<h2 className='h2__font-medium'>{Title_2}</h2>
							<p className='p__font-medium'>{Address}</p>
							<div className='flex' onClick={()=> window.location.href = `https://www.google.com/maps/place/${Link_Map}`}>
								<p className='p__font-small' >{Link_Text}</p>
								<IoIosArrowForward id='react-icon-arrow'/>
							</div>						
						</div>
					</div>	

			

					<div className='footer__icons'>
						<h2 className='h2__font-medium'>{Title_4}</h2>
						<AiFillInstagram id='react-icon-instagram'   onClick={()=> window.location.href = `https://www.instagram.com/${Link_Instagram}`}/>
						<AiFillFacebook id='react-icon-facebook' onClick={()=> window.location.href = `https://www.facebook.com/${Link_Facebook}`}/>
					</div>

				</div>

				<div className='footer__copyright flex__center-r'>
					<h4 className='h4__font-small'>©{new Date().getFullYear()} {Company_Name}</h4>
				</div>


			


			</div>
    )
}

export default Footer