import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// REDUX
import { Logout } from '../../slices/tokenReducer'; 
import  store  from '../../store'


// REACT ICONS
import { RiLogoutBoxLine } from 'react-icons/ri'


import './DashboardNavbar.css'
const DashboardNavbar = () => {
  const navigate = useNavigate()
  type AppDispatch = typeof store.dispatch
  const useAppDispatch = () => useDispatch<AppDispatch>()
  const dispatch = useAppDispatch()  

  //* Functions
  const LogoutSubmit = (e:any) => {        
      dispatch(Logout({}))            
      .unwrap()
      .then((res:any) => {
        
        navigate('/dashboard')
        ;
      })    
    }


  return (
    <div className='component__dashboardnavbar '>

      <div className='dashboardnavbar-large flex__center-c'>
        
        <div className='dashboardnavbar__home'>        
          <h2 className="h2__font-small" onClick={()=>navigate("/dashboard")}>Dashboard</h2>          
        </div>
    
        <div className='dashboardnavbar__navigate flex__center-r'>

          <div className='dashboardnavbar__navigation-buttons'>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/info")}>Info</button>
          </div>                  

          <div className='dashboardnavbar__navigation-buttons'>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/booking")}>Bokningar</button>
          </div>                   

          <div className='dashboardnavbar__navigation-buttons'>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/icecream")}>Glass</button>
          </div>   

          

          <div className='dashboardnavbar__navigation-buttons'>
            <button className='p__font-xsmall' onClick={()=>navigate("/dashboard/gallery")}>Galleri</button>
          </div>

          <div className='dashboardnavbar__navigation-buttons'>
            <button className='p__font-xsmall' onClick={()=>navigate("/dashboard/event")}>Event</button>
          </div>

          <div className='dashboardnavbar__navigation-buttons'>
            <button className='p__font-xsmall' onClick={()=>navigate("/dashboard/menu")}>Meny</button>
          </div>

          <div className='dashboardnavbar__navigation-buttons'>
            <button className='p__font-xsmall' onClick={()=>navigate("/dashboard/lunch")}>Lunchmeny</button>
          </div>

          <div className='dashboardnavbar__navigation-buttons'>
            <button className='p__font-xsmall' onClick={()=>navigate("/dashboard/sns")}>Notificationer</button>
          </div>

          

          <div className='dashboardnavbar__navigation-buttons'>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/settings")}>Settings</button>
          </div>

          <div className='dashboardnavbar__navigation-buttons'>
            <button className='p__font-xsmall ' onClick={LogoutSubmit}>Logout<RiLogoutBoxLine id='react-icon-logout'/></button>
          </div>
                    
        </div>
      </div>

      {/* <div className='dashboardnavbar-xsmall'>

        <div className='dashboardnavbar__navigation-buttons' id="dashboard-dropdown-btn">
            <button className='p__font-xsmall'>Menu</button>
            <div className='dashboardnavbar__dropdown-menu'>
              <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/menu")}>Standard Menu</button>
              <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/menupickup")}>Leftover food Menu</button>
              <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/takeaway")}>Takeaway Menu</button>
              <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/menuspecial")}>Special Menu</button>
              <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/lunchmenu")}>Lunch Menu</button>
              <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/lunchmenuweekend")}>Weekend Lunch Menu</button>
            </div>          
        </div>  

        <div className='dashboardnavbar__navigation-buttons' id="dashboard-dropdown-btn">
          <button className='p__font-xsmall'>Settings</button>
          <div className='dashboardnavbar__dropdown-menu'>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/booking")}>Booking</button>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/info")}>Info</button>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/createpdf")}>Create PDF</button>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/media")}>Media</button>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/career")}>Career</button>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/payments")}>Payments</button>
            <button  className='p__font-xsmall' onClick={()=>navigate("/dashboard/settings")}>Account Settings</button>
            <button className='p__font-xsmall' onClick={()=>navigate("/dashboard/newsletter")}>News letter</button>
          </div>          
        </div>  

      </div> */}
    

    </div>
  )
}

export default DashboardNavbar