import {useEffect, useState} from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX

import { UpdateLunch, GetLunch } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// ICONS
import { ImArrowDown, ImArrowUp } from 'react-icons/im'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

// SELF IMPORTS
import './LunchDashboard.css'
const LunchDashboard = () => {
	type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
  
	//* VARIABLES
	const [information, setInformation] = useState<any>({})	
	const [message, setMessage] = useState<string>("")		
	const [date, setDate] = useState(Date.now())
	
	//* Functions
	const Submit = async() => {
		let week = GetWeek(date)
        setMessage("laddar...") 
       
		dispatch(UpdateLunch({information, week }))
			.then(unwrapResult)
			.then((res:any) => {                                                  
				if(res?.statusCode === 200){               						                                   
					setMessage("Menyn är uppladdade!")
					                                            
				}else{                
					setMessage("Något gick fel")
				}
			}).catch(error => {
                setMessage("Något gick fel")              
            })        
               
    }  
	// Changes the value of input/textarea of NON drink meny
	const HandleChange = (e:any,category:string,j:number) => {
		const { name, value } = e.target		
		let tempInfo = information?.[category]
		tempInfo[j][name] = value			
		setInformation((prev:any)=>({...prev,[category]:tempInfo}))
	}

	
	// Change the order of items with button clicks instead of drag and drop
    const ChangeOrder = (dir:string,j:number,category:string) =>{    
		const chosenArray = information?.[category]
        if( (dir === "down" && j === chosenArray.length - 1) || (dir === "up" && j === 0) ){
        	return
        }
        var tempArr:any = chosenArray
        var value = chosenArray[j]
        tempArr.splice(j,1)
        if(dir==="down"){
        	tempArr.splice(j+1,0,value)
        }else{
       		tempArr.splice(j-1,0,value)
        }        
        setInformation((prev:any)=>({...prev, [category]:[...tempArr] }))
    }

	
	
	// Adds Empty object to menu list
    const AddEmptyToMenu = (category:string) =>{    
		var tempArr = information?.[category]              
       	tempArr.unshift({Title:"",Price:0,Info:""})        
        setInformation((prev:any)=>({...prev, [category]:[...tempArr] }))		
    }
	// Removes chosen menu item
	const RemoveItemOnMenu = (i:number,category:string) =>{
		var tempArr = information?.[category]
		tempArr.splice(i,1)
		setInformation((prev:any)=>({...prev, [category]:[...tempArr] }))		
	}



	const GetWeek = (date:number) => {    
		const currentDate: any = new Date(date);
		const startDate: any = new Date(currentDate.getFullYear(), 0, 1);
		var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
			  
		var weekNumber = Math.ceil(days / 7);
		
		return weekNumber
	  
	}
	const ChangeWeek = (dir:string) => {
	  const hour = 3600000
	  const day = hour * 24	
	  if(dir === "f"){      
		setDate(date+day*7)
	  }else if(dir === "b" ){
		setDate(date-day*7)
	  }
	  
	}

	

	//* useEffect
    // Gets info from s3
	useEffect(() => {      
        try {
            
        
            dispatch(GetLunch({week: GetWeek(date)}))
            .unwrap()
            .then(data => {            
                if(data?.statusCode === 200)     {
                    setInformation(data?.data)                       
                }else{                  
                    setInformation({
                        "Måndag":[
                            {
                                "Title":"",
                                "Price":0,
                                "Info":""            
                            }
                        ],
                        "Tisdag": [
                            {
                                "Title":"",
                                "Price":0,
                                "Info":""            
                            }
                        
                        ],
                        "Onsdag": [
                            {
                                "Title":"",
                                "Price":0,
                                "Info":""            
                            }
                        ],
                        "Torsdag": [
                            {
                                "Title":"",
                                "Price":0,
                                "Info":""            
                            }
                            
                        ],
                        "Fredag": [
                            {
                                "Title":"",
                                "Price":0,
                                "Info":""            
                            }
                        ],
                        "À la carte": [
                            {
                                "Title":"",
                                "Price":0,
                                "Info":""            
                            }
                        ]
            
                    }); 
                }                      
            }).catch(error => {
                setInformation({
                    "Måndag":[
                        {
                            "Title":"",
                            "Price":0,
                            "Info":""            
                        }
                    ],
                    "Tisdag": [
                        {
                            "Title":"",
                            "Price":0,
                            "Info":""            
                        }
                    
                    ],
                    "Onsdag": [
                        {
                            "Title":"",
                            "Price":0,
                            "Info":""            
                        }
                    ],
                    "Torsdag": [
                        {
                            "Title":"",
                            "Price":0,
                            "Info":""            
                        }
                        
                    ],
                    "Fredag": [
                        {
                            "Title":"",
                            "Price":0,
                            "Info":""            
                        }
                    ],
                    "À la carte": [
                        {
                            "Title":"",
                            "Price":0,
                            "Info":""            
                        }
                    ]
                
                });
              
            })   	
        } catch (error) {
            setInformation({
                "Måndag":[
                    {
                        "Title":"",
                        "Price":0,
                        "Info":""            
                    }
                ],
                "Tisdag": [
                    {
                        "Title":"",
                        "Price":0,
                        "Info":""            
                    }
                
                ],
                "Onsdag": [
                    {
                        "Title":"",
                        "Price":0,
                        "Info":""            
                    }
                ],
                "Torsdag": [
                    {
                        "Title":"",
                        "Price":0,
                        "Info":""            
                    }
                    
                ],
                "Fredag": [
                    {
                        "Title":"",
                        "Price":0,
                        "Info":""            
                    }
                ],
                "À la carte": [
                    {
                        "Title":"",
                        "Price":0,
                        "Info":""            
                    }
                ]
            
            }); 
        }	
	/* 	fetch(`/menu/lunch_v${GetWeek(date)}.json`)
		.then(response => {    		  
		  return response.json();
		  }).then(data => {          			
			setInformation(data);
		  }).catch((e: Error) => {         			
			
			return
		  }); */
	}, [date])
	

	return (
		<div className='container__lunchdashboard flex__center-c'>

		<h1 className='h1__font-medium'>LunchMeny</h1>

		<button onClick={Submit}>Uppdatera meny</button>
		<p>{message}</p>

		<div className='flex__center-r'>
			<IoIosArrowBack id='react-icon-arrow' onClick={()=>ChangeWeek("b")}/> 
			Vecka
			{` `}
			{GetWeek(date)}
			<IoIosArrowForward id='react-icon-arrow' onClick={()=>ChangeWeek("f")}/>
		</div>

		{Object?.keys(information).map((category,i)=>{
			const element = information[category]					
			return (
				<div className='lunchdashboard__category flex__center-c' key={`lunchdashboard__category${i}`}>
					<h1 className='h2__font-large'>{category}</h1>
					<button onClick={()=>AddEmptyToMenu(category)}>Lägg till en ny</button>
					{element?.map((item:any,j:number)=>(
						<div className='lunchdashboard__category-item flex__center-c'  key={`lunchdashboard__category-item${j}`}>
							<div><ImArrowDown className="ImArrow" onClick={()=>ChangeOrder("down",j,category)}/> <ImArrowUp className="ImArrow" onClick={()=>ChangeOrder("up",j,category)}/></div>
							<button onClick={()=>RemoveItemOnMenu(j,category)}>Ta bort {item?.Title}</button>
							<label>Title</label>
							<input type='text' value={information?.[category]?.[j]?.Title} onChange={(e)=>HandleChange(e,category,j)} name={'Title'}></input>								
							<label>Info</label>
							<textarea  value={information?.[category]?.[j]?.Info} onChange={(e)=>HandleChange(e,category,j)} name={'Info'}></textarea>
							<label>Pris</label>
							<input  type='number' value={information?.[category]?.[j]?.Price} onChange={(e)=>HandleChange(e,category,j)} name={'Price'} ></input>
						</div>
					))}
				</div>
			)
				
		})}		
	</div>
	)
}

export default LunchDashboard