import { GetBookingDates } from '../slices/bookingReducer'


import store from '../store'

export const FetchReservedDates = async(people:number, type:string, dateNow:number) => {
    try {
            
        let hasDataBeenFetched = store.getState()?.booking?.fetch
      
        let ReservedDates = store.getState()?.booking?.times
      
        
        if(!hasDataBeenFetched){
            await store.dispatch(GetBookingDates({people: people, type: type, dateNow: dateNow}))
                .unwrap()
                .then((res) => {            
                    ReservedDates = store.getState()?.booking?.times        
                   
                })
                .catch((error) => {
                    console.log(error)                                        
                    return []
                })
        }
        return ReservedDates   

    } catch (error) {
        console.log("bookingfunc error",error)              
        return []
    }

}

