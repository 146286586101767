import {useEffect, useState} from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX
import { GetBookings,DeleteBooking } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// Self imports

import './BookingDashboard.css'
const BookingDashboard = () => {
	type AppDispatch = typeof store.dispatch
  	const useAppDispatch = () => useDispatch<AppDispatch>()
  	const dispatch = useAppDispatch()

	//* INTERFACE
	interface getbookinginit {
		approved_storage: boolean
		createdOn: number
		date: number
		email: string	
		firstName: string
		lastName: string
		other: string
		people: number
		phone: string
		time: number
		type: string
		_id:string

	}
	interface initialFormData {   
		date: number,
		dateTo: number,	   
	}

	//* VARIABLES
	const [information, setInformation] = useState<getbookinginit []>([])
	const [selectedReservation, setSelectedReservation] = useState<any>({})
	const [message, setMessage] = useState<string>("")
	const initialstateForDeleteReservation = {
		approved_storage: false,
		createdOn: 0,
		date: 0,
		email: "",
		firstName: "",
		lastName: "",
		other: "",
		people: 0,
		phone: "",
		time: 0,
		type: "",
		_id:"",
	}
	const [deleteReservation, setDeleteReservation] = useState<getbookinginit>(initialstateForDeleteReservation)	
	
	const [currentInfo, setCurrentInfo] = useState<any>({})
	const [originalInformation, setOriginalInformation] = useState<any>([])
	const [formData, setFormData] = useState<initialFormData>({
		date: new Date().setHours(0,0,0,0),
		dateTo: new Date().setHours(23,0,0,0),		
	})
	const [searchbar, setSearchbar] = useState<string>("")
	




	//* FUNCTIONS
	// Updates Info in db and s3
    const SearchDb = async() => {        
        setMessage("laddar...") 
          
		dispatch(GetBookings({}))
			.then(unwrapResult)
			.then((res:any) => {                              
			
				if(res?.statusCode === 200){               
					setInformation(res?.body?.data) 
					setOriginalInformation(res?.body?.data)                
					setMessage("")
				}else{                
					setMessage("Något gick fel")
				}
			})
        
        
        
    }
	// Removes a reservation/booking
	const RemoveReservation = async(_id:string) => {
		setMessage("Tar bort...") 				
         
		dispatch(DeleteBooking({_id: _id }))
			.then(unwrapResult)
			.then((res:any) => {                              
			
				if(res?.statusCode === 200){               						
					setMessage("Reservationen är bortagen")
				}else{                
					setMessage("Något gick fel")
				}
			})
	
         
		setDeleteReservation(initialstateForDeleteReservation)	
	}



	//* SEARCH FUNCTIONS
	const Search = async() => {      
		setMessage("")   
		if(originalInformation?.length <= 0){
			await SearchDb()
		}else{					
			var filterArray:any = originalInformation    
			
			filterArray = filterArray.filter((item:any)=>{    
			return formData.date <= item.date && formData.dateTo >= item.date
			})
			
			
			if(searchbar !== ""){          
			var returnarray:any = []
			const searchFunc = (paymentitem:any,originalObject:any,found:any,newregex:any) =>{	
				if(found) return found
				
				if(Array.isArray(paymentitem)){
				
				for(let j = 0;j<paymentitem.length;j++){  		
					found = searchFunc(paymentitem[j],originalObject,found,newregex)
					if(found) return found
				} 
				}else if(typeof paymentitem === "object" && paymentitem !== null){  
				// eslint-disable-next-line
				Object.keys(paymentitem).map(key=>{     
					found = searchFunc(paymentitem[key],originalObject,found,newregex)      
					if(found) return found            
				})  	
				}else if(newregex?.exec(paymentitem)){  	
		
				found = true
				returnarray.push(originalObject)        
				return found
				}else{  	
				return found
				}		
				return found      
			}
		
			for(let i = 0; i<filterArray.length;i++){
				const paymentitem = filterArray[i]
				var found = false
				var searchValue = searchbar
				var searchValueReg = `\\b${searchValue}\\b`
				var newregex = new RegExp(searchValueReg,"gi")
				searchFunc(paymentitem,paymentitem,found,newregex)
			}
			setInformation(returnarray)  
			}else{
			setInformation(filterArray) 
			}
		}
	}
	const clearSearchOptions = ():void => {
		setFormData({
			date: new Date().setHours(0,0,0,0),
			dateTo: new Date().setHours(23,0,0,0),		
		})
		setSearchbar("")

	}
	const SearchByDate = (e:any):void => {   
		const { name, value } = e.target			
		setFormData(prevState => ({
			...prevState,
			[name]: new Date(value).setHours(0,0,0,0)
	}))		
	}	
	
	return (
		<div className='container__bookingdashboard flex-c'>
		

			<h1 className='h1__font-medium'>Information</h1>

			<button onClick={Search}>Sök</button>
			<p>{message}</p>

			{/* SEARCH BAR */}
			<div className='dashboardbooking__search'>
                
                    
				<label htmlFor='ffrom'>Datum Från</label>
				<input type="date" id="ffrom" placeholder="From" onChange={SearchByDate} value={
				  `${new Date(formData?.date).toLocaleDateString()}`
				  }
				  name="date">
				</input>                    
  
				<label htmlFor='fto'>Datum Till</label>
				<input type="date" id="fto" placeholder="To" onChange={SearchByDate} value={
				  `${new Date(formData?.dateTo).toLocaleDateString()}`
				  }
				  name="dateTo">
				  
				</input>
  
			<div>
			  <label>Sök</label>
			  <input type="text" onChange={(e)=>setSearchbar(e?.target?.value)} value={searchbar} name="searchbar"></input>
			</div>
    	
			<button onClick={clearSearchOptions}>Rensa inställningar för sökmotorn</button>        
  
  
		
		  </div>


			{/* Reservation data */}
			<div className='bookingdashboard flex-c'>
				{information?.map((bookingItem,i:number)=>{
					if(!selectedReservation[i]){					 
						return(
							<div className='bookingdashboard__bookingitem flex' key={`bookings${i}`} onClick={()=>setSelectedReservation((prev:any)=> ({...prev, [i]: true}))}>
								<p className='p__font-xsmall'>{new Date(bookingItem?.date).toLocaleDateString()} kl.{new Date(bookingItem?.time).toLocaleTimeString("sv-SE", { hour: "2-digit", minute: "2-digit" })}</p>
								<p className='p__font-xsmall'><strong>Namn</strong>: {bookingItem?.firstName}</p>
								<p className='p__font-xsmall'><strong>Efternamn</strong>: {bookingItem?.lastName}</p>																	
							</div>							
						)
					}else{
						return (
							<div className='bookingdashboard__bookingitem-expanded flex-c' key={`bookings${i}`} onClick={()=>setSelectedReservation((prev:any)=> ({...prev, [i]: false}))}>
								<p className='p__font-xsmall'>{new Date(bookingItem?.date).toLocaleDateString()} kl.{new Date(bookingItem?.time).toLocaleTimeString("sv-SE", { hour: "2-digit", minute: "2-digit" })}</p>
								<p className='p__font-xsmall'><strong>Namn</strong>: {bookingItem?.firstName}</p>
								<p className='p__font-xsmall'><strong>Efternamn</strong>: {bookingItem?.lastName}</p>	
								<p className='p__font-xsmall'><strong>Antal gäster</strong>: {bookingItem?.people}</p>	
								<p className='p__font-xsmall'><strong>Telefon</strong>: {bookingItem?.phone}</p>	
								<p className='p__font-xsmall'><strong>typ</strong>: {bookingItem?.type}</p>	
								<p className='p__font-xsmall'><strong>_id</strong>: {bookingItem?._id}</p>	
								<p className='p__font-xsmall'><strong>Övrig info</strong>: {bookingItem?.other}</p>	
								<p className='p__font-xsmall'><strong>Accepterat lagring av uppgifter</strong>: {bookingItem?.approved_storage ? "Ja" : "Nej"}</p>	
								<button onClick={()=>setDeleteReservation(bookingItem)}>Ta bort Bokning</button>
							</div>
						)
							
					}
					
				})}		
			</div>


			{/* Delete popup */}
			{deleteReservation?._id && 
			<div className='bookingdashboard__delete'>
				<div className='bookingdashboard__delete-blur' onClick={()=>setDeleteReservation(initialstateForDeleteReservation)}></div>
				<div className='bookingdashboard__delete-container flex__center-c'>
				<p className='p__font-medium'><strong>Är du säker på att du vill ta bort denna reservation?</strong></p>
					<p className='p__font-xsmall'>{new Date(deleteReservation?.date).toLocaleDateString()} kl.{new Date(deleteReservation?.time).toLocaleTimeString("sv-SE", { hour: "2-digit", minute: "2-digit" })}</p>
					<p className='p__font-xsmall'><strong>Namn</strong>: {deleteReservation?.firstName}</p>
					<p className='p__font-xsmall'><strong>Efternamn</strong>: {deleteReservation?.lastName}</p>
					<p className='p__font-xsmall'><strong>_id</strong>: {deleteReservation?._id}</p>
					
					<div className='flex__center-c'>
						
						<button onClick={()=>RemoveReservation(deleteReservation?._id)}>Ja</button>
						<button onClick={()=>setDeleteReservation(initialstateForDeleteReservation)}>Avbryt</button>
					</div>
				</div>
			</div>
			}
		</div>
	)
}

export default BookingDashboard