import { useState, useEffect } from 'react'
import { unwrapResult } from '@reduxjs/toolkit';

import FileBase from 'react-file-base64'

// REDUX
import { DeleteImage, UploadImage, GetGallery } from '../../slices/tokenReducer'; 
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'

// ICONS


// SELF IMPORTS

import './GalleryDashboard.css'


const GalleryDashboard = () => {
    const dispatch: AppDispatch = useDispatch()

    //* VARIABLES
    interface imageFileInit {     
     
      file:{},
      base64:string,
      size: string,
      type: string,
      name: string,
         
    }
    const [imageFile, SetImageFile] = useState<imageFileInit>({            
      file:{},
      base64:"",
      size: "",
      type: "",
      name: "",      
    });
    const [newText, setNewText] = useState<string>("")
    const [message, setMessage] = useState<string>("");
    interface Igalleryfiles{
        key: string
        text: string        
    }
    const [information, setInformation] = useState<Igalleryfiles []>([]) 

  
    //* FUNCTIONS  
    // Upload image  
    const Submit = async() => {
     
        if(!imageFile?.base64 && newText){
            setMessage("Du kan inte ladda upp en ny text utan bild")            
            return
        }
        setMessage("laddar...")
		dispatch(UploadImage({imageFile: imageFile, newText: newText, Gallery: information, }))
			.then(unwrapResult)
			.then((res:any) => {                                                  
				if(res?.statusCode === 200){               						                                   
					setMessage("Bilden är uppladdad!")
					
				}else if(res?.statusCode === 415){
					setMessage("Fel filformat, använd hellre .webp/.jpg/.png")
				}else{                
					setMessage("Något gick fel")
				}
			}).catch(error => {
                setMessage("Något gick fel")               
            })        
               
    }
    // delete image in s3 and in gallery.json
    const DeleteItem = async(imageurl:string,index:number) => {

        const newItem = information?.filter(( item, i) => {
            if(i === index){
                
            }else{ 
                return item
            }                
        })
        setInformation(newItem)
        
   /*      setMessage("laddar...") 
        
		dispatch(DeleteImage({_id: imageurl, oldInformation: information, }))
			.then(unwrapResult)
			.then((res:any) => {                                                  
				if(res?.statusCode === 200){               						                                   
					setMessage("Bilden är bortagen")
					
				}else{                
					setMessage("Något gick fel")
				}
			})   */      
               
    }        

    // Fetches image _ids from gallery
    useEffect(() => {       
        dispatch(GetGallery({}))
        .unwrap()
        .then(res => {            
            setInformation(res?.Gallery)                       

        })
       /*  fetch(`/gallery/gallery.json`)
        .then(response => {
            return response.json();
        }).then(data => {          
           
            setInformation(data?.Gallery);                
        }).catch((e: Error) => {         
            return
        }); */
    }, [])

    const ChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>,index:number) => {
       
        const newItem = information?.map(( item, i) => {
            if(i === index){
                return { ...item, text: event?.target.value}
            }else{ 
                return item
            }                
        })
        setInformation([...newItem])
    }

    

  return (
    <div className='container__gallerydashboard flex__center-c'>

        <h1 className='h1__font-medium'>Galleri</h1>        
        <p>{message}</p>

        <div className='servicedashboard__intro'>
          <p className='p__font-xsmall'>- Om du vill ladda upp en ny bild med text, klicka på Välj fil (browse...), sen klicka på "Uppdatera Galleriet". Du kan bara ladda upp en bild i taget</p>
          <p className='p__font-xsmall'>- Om du klickar delete eller ändrar texten på dem äldre bilderna så måste du sedan klicka på "Uppdatera Galleriet" för att förändringen skall ske</p>            
        </div>
        <button  style={{marginBottom:"3rem"}} onClick={Submit}>Uppdatera Galleriet</button>

      <div className='gallerydashboard__upload flex__center-c' style={{width:"90%",marginTop:"2rem", border:"1px solid grey", padding:"0.5rem"}}>
        <h2 style={{marginBottom:"2rem"}}>Skapa en ny bild</h2>

        <p style={{margin:0}}>Välj en bild fil</p>
        <FileBase 
          type="file"
          accept='.webp' 
          id="fcv" 
          multiple={false}                       
          onDone={(base64:any) => {SetImageFile(base64)} }
          name={"imagemediagallery"}            
          required
          >
        </ FileBase>
        <img src={`${imageFile?.base64}`} alt="" style={{width:"10rem",height:"10rem",objectFit:"cover"}}/>
        
        <p style={{margin:"2rem 0 0 0"}}>Skriv en text till följande bild</p>
        <textarea value={newText} style={{width:"100%"}} onChange={(event)=>setNewText(event?.target?.value)} />
        
             
      </div>

      <div className='gallerydashboard__images flex__center-c' style={{width:"90%",marginTop:"2rem", border:"1px solid grey", padding:"0.5rem"}}>        
        <h2 style={{marginBottom:"2rem"}}>Uppdatera text eller ta bort äldre bilder</h2>
          {information?.map((file,i)=>(
            <div className='gallerydashboard__images-img flex__center-c' key={`media${i}`} style={{margin:"1rem 0", width:"95%",height:"100%", border:"1px dotted grey"}}>
              <img src={`/${file?.key}`} alt="gallerydashboard" style={{width:"10rem",height:"10rem",objectFit:"cover"}}/>
              <textarea style={{width:"95%"}} value={file?.text}  onChange={(event)=>ChangeText(event,i)} />
              <button onClick={()=>DeleteItem(file?.key,i)}>Delete</button>
            </div>
          ))}      
        
      </div>
        
      

    </div>
  )
}

export default GalleryDashboard