import { useState, useEffect } from 'react'

//redux
import { GetGallery } from '../../slices/tokenReducer'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'

// mui
import Button from '@mui/material/Button'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// Swiper
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


// Self imports
import './Gallery2.css'

const imagesinit = [
    "https://www.google.com/maps/embed?pb=!4v1696357820778!6m8!1m7!1sCAoSLEFGMVFpcFB4OFhyZ2pGeHdkR095U0tFYXpGZjhHTUtVUlJFTnRDQWpyUkdz!2m2!1d57.69033779!2d11.90600109!3f180.32445910753313!4f-10.014887214543663!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1696357933936!6m8!1m7!1sCAoSLEFGMVFpcFB4OFhyZ2pGeHdkR095U0tFYXpGZjhHTUtVUlJFTnRDQWpyUkdz!2m2!1d57.69033779!2d11.90600109!3f58.865631173736865!4f-18.620824160470647!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1696357952593!6m8!1m7!1sCAoSLEFGMVFpcE15MUcxMDhkc2xudzBHaTZjWnVaTWEyd3hzWXBFYThCTWNfb2Zu!2m2!1d57.69015858!2d11.90561486!3f94.48446911902366!4f-19.75506256032611!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1696357971287!6m8!1m7!1sCAoSLEFGMVFpcE9uUlRDZmRsRVZCQThuX1pqbXNQZ2taeWlvZWluaFFvbTNIWHFN!2m2!1d57.69043972!2d11.9059965!3f172.59615596562455!4f-5.421047513657271!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1696357987659!6m8!1m7!1sCAoSLEFGMVFpcE1TbVVKcmZtMGQwbUVZa2loR2VvTWgxS0JWLWQtWngwY1Z5aGtm!2m2!1d57.6902962!2d11.90602255!3f188.64199224005034!4f-16.377492651551847!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1696358005080!6m8!1m7!1sCAoSLEFGMVFpcFB2MXZ1OEtjM3FwbU5TSlR3cC1VTnhQUW5PT01ZRy0yZFBCVFNW!2m2!1d57.69015714!2d11.90579457!3f312.205215855642!4f-8.966350456345793!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1696358040026!6m8!1m7!1sCAoSLEFGMVFpcFBtTHVWb24zVWc5MUVQcnlLbVNjREhveWdKZEVmUkdnV1JTSzB5!2m2!1d57.69035067!2d11.9060601!3f344.8374009978618!4f-20.683016518724102!5f0.7820865974627469",

]
interface inputinit {[key:string]:string}
const Gallery2 = ({Title_1,IntroText_1}:inputinit) => {

    // VARIBLES

    const [ googleImages ] = useState<string []>(imagesinit)
    interface Igalleryfiles{
        key: string
        text: string        
    }
    const [ galleryFiles, setGalleryFiles ] = useState<Igalleryfiles []>([])
    const [ section, setSection ] = useState("imgview")

    const dispatch: AppDispatch = useDispatch()
  // sets images to usestate
  useEffect(() => {       
    async function func () {
        await dispatch(GetGallery({}))
        .unwrap()
        .then(res => {            
           setGalleryFiles(res?.Gallery)
        })    
    }
    func()
    
  }, [])


    return (
        <section className='container__gallery2 flex__center-c' >
            

            <h2 className='h2__font-medium'>{Title_1}</h2>
            <p className='p__font-medium'>{IntroText_1}</p>

            <div className='flex' style={{justifyContent:"center",flexWrap:"wrap", marginBottom:"2rem"}}>
                <Button variant="contained" startIcon={<ThreeDRotationIcon />} sx={{mr:"0.5rem",bgcolor:"#EFC050",color:"#ffffff",fontWeight:900}} onClick={()=> setSection("3dview")}>
                    3D vy av restaurangen
                </Button>

                <Button variant="contained" startIcon={<PhotoCameraIcon />} sx={{ml:"0.5rem",bgcolor:"#EFC050",fontWeight:900}} onClick={()=> setSection("imgview")}>
                    Galleri
                </Button>
            </div>




            {/* 3D Images */}
            {section === "3dview" && 
            <div className='flex__center-c'>            
                {googleImages?.map((image,i) => (
                    <iframe key={`3dimage${i}`} title="first3d" src={image} width="600" height="450"  style={{border:"none", marginBottom:"2rem",maxWidth:"95vw"}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                ))}
            </div>
            }

            {/* Images with text */}
            {section === "imgview" && 
            <div className='flex__center-c'>            
                {galleryFiles?.map((file,i) => (
                    <div style={{maxWidth:"95vw", marginBottom:"2rem"}} key={`imgview${i}`}>
                        <img src={`/${file?.key}`} alt='' style={{maxWidth:"95vw"}}/> 
                        <p className="p__font-small">{file?.text}</p>
                    </div>
                ))}
            </div>
            }
                        
        </section>
    )
}

export default Gallery2