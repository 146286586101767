import {useState} from 'react'
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX
import { ConfirmForgotPassword } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// REACT ICONS

import './ForgotPasswordPage.css'
const ForgotPasswordPage = () => {
  	type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()

	let { id, userid } = useParams();  
	const [msg, setMsg] = useState<string>("")	
  	const [password, setPassword] = useState<string>("")

  	const submit = (e:any) => {	
		e.preventDefault()
		setMsg("")      
		dispatch(ConfirmForgotPassword({id, userId: userid, password}))
		.then(unwrapResult)
		.then((res:any) => {	
			if(res?.statusCode === 200){
				setMsg("Ditt lösenord skall vara bytt")
			}else{
				setMsg("Något gick fel")
			}
			
			
		
		})
	}	 


  return (
    <div className='container__forgotpasswordpage flex__center-c'>
      <form className='flex__center-c'>			
			<h3 className='h4__font-medium'>Glömt lösenordet</h3>
			  <label className='p__font-small'>Nytt lösenord</label>
			<input type="text" name='email' value={password} onChange={(e)=>setPassword(e?.target?.value)}></input>	  
			<button className='p__font-small' onClick={submit}>Uppdatera lösenord</button>              	  
			<p className='p__font-small'>{msg}</p>
			<input type="submit" hidden />
		</form>	

    </div>
  )
}

export default ForgotPasswordPage