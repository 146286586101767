import React from 'react'
import { useNavigate } from 'react-router-dom'
// REDUX


// REACT ICONS
import { IoIosArrowForward } from 'react-icons/io'

// SELF IMPORTS

import './IntroText.css'

interface IntroTextInit {
  Title_1: string
  Title_2: string
  Info_1: string
  Image:string
  Link_Text: string
  Link: string
  Bg: string
}

const IntroText = ({Title_1,Title_2,Info_1,Image,Link_Text, Link, Bg}:IntroTextInit) => {
  const navigate = useNavigate()
    
  return (
    <section className='container__introtext flex__center-c' style={{backgroundColor:Bg}}>     

      {/* Container which is to the right and 80vw */}
      <div className='introtext__80vw'>

        {/* Title */}
        <div className='introtext__title flex-c'>
          <h1 className='h1__font-large'>{Title_1}</h1>        
          <h1 className='h1__font-large'>{Title_2}</h1>        
          <p className='p__font-small'>{Info_1}</p>
        </div>


        {/* Link */}
        {Link &&
        <div className='introtext__link flex' >
          <div className='flex' onClick={()=>navigate(`${Link}`)}>
            <p className='p__font-small' >{Link_Text}</p>
            <IoIosArrowForward id='react-icon-arrow'/>
          </div>
        </div>
        }

        {/* Image */}
        <div className='introtext__img flex'>
          <img src={Image} alt="introText" />   
        </div>
        
      </div>
      
   
    </section>
  )
}

export default IntroText