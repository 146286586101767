import React from 'react'

import './Loading.css'
const Loading = () => {
  return (      
    <div className='loadingscreen flex__center-c'>    
        <div className='loadingscreen__container'>
            <div className='loadingscreen__orbit'>
                <div className='loadingscreen__circle'></div>
            </div>
            <div className='main-icon'>
                <h1 className='h1__font-small'>{`Klippans`}</h1>
                <h1 className='h1__font-small'>{`Konstcafé`}</h1>
            </div>                      
        </div>
        <h5 className='h4__font-medium'>Laddar</h5>
    </div>    
  )
}

export default Loading