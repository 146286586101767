import {useEffect, useState} from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import FileBase from 'react-file-base64'
import { v4 as uuidv4 } from 'uuid';



// ICONS
import { ImArrowDown, ImArrowUp } from 'react-icons/im'

// REDUX
import { UpdateIcecream, GetIcecream } from '../../slices/tokenReducer'; 
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'


// ICONS


// SELF IMPORTS

import './IcecreamDashboard.css'


const IcecreamDashboard = () => {
    const dispatch: AppDispatch = useDispatch()

    //* INTERFACE
    interface serviceiteminit {
        _id:string
        Title: string
        Intro: string    
        Image: string
        ImageFile: {
            base64: string
            size: string
            name: string
            type: string
        }
    }
     //* VARIABLES
    const [information, setInformation] = useState<any>([])
    const [message, setMessage] = useState<string>("")
	const [deletedServices, setDeletedServices] = useState<string []>([])

  
    //* FUNCTIONS  
    // Upload image  
    const Submit = async() => {
        setMessage("laddar...")         
        dispatch(UpdateIcecream({information, deletedServices }))
            .then(unwrapResult)
            .then((res:any) => {                                                  
                if(res?.statusCode === 200){               						                                   
                    setMessage("Glasserna är uppladdade!")
                    
                }else if(res?.statusCode === 415){
                    setMessage("Fel filformat, använd hellre .webp/.jpg/.png")
                }else{                
                    setMessage("Något gick fel")
                }
            })        
               
    }      

    // Adds Image to the Information Array which will be uploaded to s3
    const handleImageUpload = (file:any,index:number) =>{
    
        const newArray = information.map((item: any, i:number) => {
        if (index === i) {
            return { ...item, ImageFile: {base64: file?.base64, name: file?.name, size: file?.size, type: file?.type} };
        } else {
            return item;
        }
        });
        
        setInformation(newArray)  
    }
    // Change the order of items with button clicks instead of drag and drop
    const changeOrder = (dir:string,j:number) =>{    
        if( (dir === "down" && j === information.length - 1) || (dir === "up" && j === 0) ){
        return
        }
        var tempArr:any = information
        var value = information[j]
        tempArr.splice(j,1)
        if(dir==="down"){
        tempArr.splice(j+1,0,value)
        }else{
        tempArr.splice(j-1,0,value)
        }
       
        setInformation([...tempArr])

    }
    // Adds an empty service item
    const AddNewServiceToArray = () =>{
        const emptyService = {
                _id: uuidv4(),
        Title: "",
        Intro: "",       
        Image:"",
        ImageFile: {
            base64: "",
            name: "",
            type: "",
            size: "",
        } 
        }
        setInformation((prev:serviceiteminit []) => [emptyService,...prev])
    }
    // Delete service item
    const DeleteServiceItem = (i:number) =>{
        setDeletedServices(prev=>[...prev,information[i].Image])
        setInformation((prevState: serviceiteminit []) => (      
            information?.filter((a:serviceiteminit)=> information?.indexOf(a) !== i )              
        )
        )    
    }
    // Changes inputs/textarea for s3
    const handleChange = (e:any,index:number) => {
        const { name, value } = e?.target
      
        const newArray = information.map((item: any, i:number) => {
        if (index === i) {
            return { ...item, [name]:value };
        } else {
            return item;
        }
        });
       
        setInformation(newArray) 
    }


    // Fetches image _ids from gallery
    useEffect(() => {       
        dispatch(GetIcecream({}))
        .unwrap()
        .then(data => {            
            setInformation(data?.Icecream)
        })  
       /*  fetch(`/icecream/icecream.json`)
        .then(response => {
            return response.json();
        }).then(data => {          
          
            setInformation(data?.Icecream);                
        }).catch((e: Error) => {         
            return
        }); */
    }, [])



  return (
    <div className='container__servicedashboard flex__center-c'>

        <h1 className='h1__font-medium'>Gelato</h1>

        <div className='servicedashboard__update-btn flex'>
            <button onClick={Submit}>Uppdatera data</button>
            <button onClick={AddNewServiceToArray}>Lägg till en ny glass</button>
        </div>
        <div className='servicedashboard__intro'>
            <p className='p__font-xsmall'>- Inga förändringar sker försen du klickar på Uppdatera data</p>
            <p className='p__font-xsmall'>- Klicka på lägg till en ny glass för att lägga till en ny tom mall</p>
            <p className='p__font-xsmall'>- Använd pilarna för att ändra ordningen på glassarna</p>
            <p className='p__font-xsmall'>- Om du ska ladda upp flera bilder samtidigt så måste dem vara mindre än 10MB, ladda upp i flera omgångar om det är för stort </p>
        </div>
        <p>{message}</p>

        {information?.map((serviceItem:serviceiteminit,i:number)=>{
        return(
            <div className='servicedasboard__item flex__center-c' key={`category${i}`}>

                <div className='servicedasboard__item-arrow flex'>
                    <div><ImArrowDown className="ImArrow" onClick={()=>changeOrder("down",i)}/> <ImArrowUp className="ImArrow" onClick={()=>changeOrder("up",i)}/></div>
                    <button onClick={()=>DeleteServiceItem(i)}>Ta bort glass</button>
                </div>

                <label>Title</label>            
                <textarea name="Title" value={serviceItem?.Title} onChange={(e)=>handleChange(e,i)}></textarea>
                
                <label>Intro</label>              
                <textarea name="Intro" value={serviceItem?.Intro} onChange={(e)=>handleChange(e,i)}></textarea>
                
                <div className='image__uploads flex__center-c'>              
                    <FileBase 
                        type="file"
                        accept='.webp' 
                        id="fcv" 
                        multiple={false}                       
                        onDone={(base64:any) => {handleImageUpload(base64,i)} }
                        name={`serviceimage${i}`}            
                        required
                        >
                    </ FileBase>
                    <div className='flex-c'>
                        <p className='p__font-small'>{}</p>
                        <img src={serviceItem?.ImageFile ? serviceItem?.ImageFile?.base64 : `/${serviceItem?.Image}` } alt={"none"} className="small__image-prev" />
                    
                    </div>  
                </div>

      
            
          </div>
        )
      })}
    </div>
  )
}

export default IcecreamDashboard