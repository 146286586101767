import { configureStore, combineReducers } from "@reduxjs/toolkit";
import tokenReducer from './slices/tokenReducer'
import infoReducer from './slices/infoReducer'
import bookingReducer from './slices/bookingReducer'
import { createStateSyncMiddleware, withReduxStateSync,initStateWithPrevTab } from 'redux-state-sync';
export type AppDispatch = typeof store.dispatch
const reducer = combineReducers({   
    token: tokenReducer,
    info: infoReducer,
    booking: bookingReducer
})
const rootReducer = withReduxStateSync(reducer);
const extraMiddlewares = [createStateSyncMiddleware({    })]
const store = configureStore({
    reducer:rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [/* if you needed */],
        },
      }).concat(extraMiddlewares),
})

initStateWithPrevTab(store);

export default store

