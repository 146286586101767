import React from 'react'

import { AiFillInstagram } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { FaMapMarkerAlt } from 'react-icons/fa'

import './SmIcons.css'

interface SmIconsinit {
    Link_Instagram: string
    Link_Facebook: string
    Link_Map: string
  }

const SmIcons = ({Link_Instagram,Link_Facebook,Link_Map}:SmIconsinit) => {
  return (
    <div className='component__smicons flex__center-c'>
        <AiFillInstagram id='react-icon-instagram'  onClick={()=> window.location.href = `https://www.instagram.com/${Link_Instagram}`}/>
        <BsFacebook id='react-icon-facebook'  onClick={()=> window.location.href = `https://www.facebook.com/${Link_Facebook}`}/>
        <FaMapMarkerAlt id='react-icon-map'  onClick={()=> window.location.href = `https://www.google.com/maps/place/${Link_Map}`}/>



    </div>
  )
}

export default SmIcons