import {useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX
import { AbortBooking } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// REACT ICONS


// SELF IMPORTS

import './AbortBookingPage.css'



interface abortinit {[key:string]:string}
const AbortBookingPage = ({Title_1, Abort_Button, Home_Button}:abortinit) => {
	type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	let { id } = useParams();  
	const [msg, setMsg] = useState<string>("")
	const [aborted, setAborted] = useState<boolean>(false)

	const submit = () => {
		try {
		setMsg("")      
			dispatch(AbortBooking({_id:id}))
			.then(unwrapResult)
			.then((res:any) => {
	
			if(res?.statusCode === 200){
				setMsg("Din tid är nu avbokad")
				setAborted(true)
			}else{
				setMsg(res?.body?.msg)
			}
			})      
		} catch (error) {
		
		}
	}  
	return (
	<div className='container__abortbookingpage flex__center-c'>

		{!aborted ? 
			<div className='abortbookingpage__message'>            
			<h4 className='h4__font-small'>{Title_1}</h4>        
			
			<button onClick={()=>submit()} className='btn-oval'>{Abort_Button}</button>
			
			<p className='p__font-small'>{msg}</p>      
			</div>
		:
			<div className='abortbookingpage__message'>     
			<h4 className='h4__font-small'>{msg}</h4>         
			<button onClick={()=>navigate("/")} className='btn-oval'>{Home_Button}</button>
			</div>
		}

	

	</div>
	) 
}

export default AbortBookingPage