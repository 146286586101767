import { useLayoutEffect, useRef, useState,useEffect} from 'react'
import { gsap } from 'gsap'
import { useNavigate } from 'react-router-dom'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// mui
import Dialog from '@mui/material/Dialog'

// REDUX

import { GetEvent } from '../../slices/tokenReducer'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'
// REACT ICONS


// SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade,Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';

// SELF IMPORTS
import { Loading } from '../../components/index'
import './Event.css'


gsap.registerPlugin(ScrollTrigger);



interface eventinit {[key:string]:string}
const Event = ({Title_1,IntroText_1,Image_1,Navgiate_Button_1}:eventinit) => {
  const navigate = useNavigate()
  const event_ref = useRef(null)

  const dispatch: AppDispatch = useDispatch()

  //* States
  const [loading, setLoading] = useState(true)

  interface choseneventinit {
    Title:string
    Info:string
    Images: string[]
  }    
  interface infoinit {[key:string]:choseneventinit}        
                       
  const [information, setInformation] = useState<infoinit>({})
  const [chosenEvent, setChosenEvent] = useState<choseneventinit>({
    Title:"",
    Info:"",
    Images: []
  })

  //* Functions
  const ChangeEvent = (event:string) => {
    setChosenEvent(information[event])
  }

 


  //* useeffect
  // scrolls to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //Fetch event.json
  useEffect(() => {       

    dispatch(GetEvent({}))
    .unwrap()
    .then(data => {            
        setInformation(data)                       
    })    
  
   
      return () => {}
  }, [])

  

  //* GSAP
  useLayoutEffect(() => {
   
    
    const ctx = gsap.context(()=>{ 
   
      gsap.to(`#event_img_1`,{
        yPercent:50,
        scrollTrigger:{
          trigger:event_ref.current,
          start:"top top",
          end:"max",
          scrub:true,
          /* markers:true, */
       
        }
      })
 
        
    },event_ref)

    return () => {
      
      ctx.revert()
    };

    
  }, [])

    //* Image Dialog for viewing fullscreen image */    
    interface FullScreebDialogProps {
        open: boolean
        image: string

    }
    const [openImageDialog, setOpenImageDialog] = useState(false)
    const [selectedImage, setSetselectedImage] = useState("")
    const FullScreebDialog = (props: FullScreebDialogProps) => {
        const { image, open } = props;

        const handleClose = () => {    
            setOpenImageDialog(false)
        };
            
        return (
        <Dialog onClose={handleClose} open={open}>
                <img
                    //src={`https://${process.env.REACT_APP_CDN_URL}/${image}?w=164&h=164&fit=crop&auto=format`}
                    src={`/${image}?w=164&h=164&fit=crop&auto=format`}
                    //srcSet={`https://${process.env.REACT_APP_CDN_URL}/${selectedImage}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={image}
                    loading="lazy"
                />
            </Dialog>
        );
    }

  return (
    <section className='container__event flex-c' ref={event_ref}>     

      {/* Image */}
      <img id="event_img_1" className="event-img" src={`/${Image_1}`} alt="event1" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   

      {/* Event */}    
      <div className='event flex__center-c'>
        <h2 className='h2__font-medium'>{Title_1}</h2>
        <p className='p__font-medium'>{IntroText_1}</p>

        <div className='event__dropdown flex__center-c'>
          <ul>
            <li className='flex__center-c'><button className='p__font-small'>Välj ett event</button>
              <ul className='flex__center-c'>
                {Object?.keys(information)?.map((key:string,i:number)=>{
                  let element = information[key]
                  return (
                  <li key={`event${i}`}>
                    <button onClick={()=>ChangeEvent(key)} className='p__font-small'>{element?.Title}</button>
                  </li>)
                })}                                
              </ul>
            </li>
          </ul>
        </div>

        <div className='event-line'/>


        <div className='event__show flex__center-c'>
          <h2 className='h2__font-medium'>{chosenEvent?.Title}</h2>
          <p className='p__font-medium'>{chosenEvent?.Info}</p>
          <div className='sqrbtn-second flex__center-c'>
     
          <button className='p__font-medium square-btn' onClick={()=>navigate("/contact")}>{Navgiate_Button_1}</button>
          { chosenEvent?.Images.length > 0 && <p className='p__font-medium'>Klicka för se bilden i helskräm</p> }
        </div>
          {chosenEvent?.Images?.length > 0 && 
            <Swiper 
              className='flex__center-r'              
              modules={[Autoplay,EffectFade]} 
              effect={"fade"}          
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              loop={true}
              spaceBetween={30}                   
              >        
            {chosenEvent?.Images?.map((img,i) => {        
              return <SwiperSlide key={i}><img onClick={()=>{setOpenImageDialog(true); setSetselectedImage(img) }} className='swiper-img' src={`/${img}`}  alt='swipercar'/></SwiperSlide>;
            })}
            </Swiper>    
            
          }

            <FullScreebDialog open={openImageDialog} image={selectedImage}/>
        </div>
        
       
                        
      </div>
     

      {/* LOADING SCREEN */}
      {loading && 
        <Loading />
      }
    
    </section>
  )
}

export default Event