import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


const serverAddress = process.env.NODE_ENV === "production" ? "https://api.klippanskonstcafe.se" : "http://localhost:5000"


//* Booking
export const PostBooking = createAsyncThunk(
    "post/PostBooking",
    async(info: any,{ getState } ) => {
        const res = await axios.post(`${serverAddress}/postbooking`,info)        
        return res.data
})
export const GetBookingTimes = createAsyncThunk(
    "get/GetBookingTimes",
    async(info: any,{ getState } ) => {
        const res = await axios.get(`${serverAddress}/getbookingtimes/?type=${info?.type}&people=${info?.people}&date=${info?.date}`)        
        return res.data
})
export const GetBookingDates = createAsyncThunk(
    "get/GetBookingDates",
    async(info: any,{ getState } ) => {
        const res = await axios.get(`${serverAddress}/getbookingdates/?type=${info?.type}&dateNow=${info?.dateNow}`)        
        return res.data
})


interface bookinginit {     
    fetch:boolean
    times: number []
}

const initalStateValue:bookinginit = {  
    fetch:false,
    times:[]     
}


const postSlice = createSlice({
    name:"booking",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {                
        builder.addCase(GetBookingDates.fulfilled, (state, action) => {    
           
            let filteredRes:any = []
            for (let i = 0; i < action?.payload?.body?.data?.length; i++) {
                const reservedDate:any = action?.payload?.body?.data[i];
                filteredRes.push( {date: parseInt(reservedDate.sk)} )
                
            }                                              
                    
            return {fetch:true, times: filteredRes }
        })              
    }
})

const { reducer } = postSlice

export default reducer

 