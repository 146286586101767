import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import jwt_decode from 'jwt-decode'



const serverAddress = process.env.NODE_ENV === "production" ? "https://api.klippanskonstcafe.se" : "http://localhost:5000"

//* Booking 


export const GetBookings = createAsyncThunk(
    "get/GetBookings/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        const res = await axios.get(`${serverAddress}/getbookings/?AccToken=${state?.token?.AccToken}`)        
        return res.data
})
export const AbortBooking = createAsyncThunk(
    "delete/AbortBooking",
    async(info: any,{ getState } ) => {
        const res = await axios.delete(`${serverAddress}/abortbooking/?_id=${info?._id}`)        
        return res.data
})
export const DeleteBooking = createAsyncThunk(
    "delete/DeleteBooking/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        const res = await axios.delete(`${serverAddress}/deletebooking/?_id=${info?._id}&AccToken=${state?.token?.AccToken}`)        
        return res.data
})




//* Contact
export const postContact = createAsyncThunk(
    "post/postContact",
    async(info: any,{ getState } ) => {
        const res = await axios.post(`${serverAddress}/postcontact`,info)        
        return res.data
})





//* Event
export const AddEvent = createAsyncThunk(
    "post/AddEvent/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken 
        const res = await axios.post(`${serverAddress}/addevent`,info)        
        return res.data
})
export const DeleteEvent = createAsyncThunk(
    "put/DeleteEvent/admin",
    async(info: any,{ getState } ) => {  
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken       
        const res = await axios.put(`${serverAddress}/deleteevent`,info)        
        return res.data
})
export const GetEvent = createAsyncThunk(
    "get/GetEvent",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`/event/event.json`,
            {
                params:{ t: new Date().getTime()} // removes cache ??
            }
        )           
        console.log("res",res)
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})






//* Gallery
export const UploadImage = createAsyncThunk(
    "post/UploadImage/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken    
        const res = await axios.post(`${serverAddress}/uploadimage`,info)        
        return res.data
})
export const DeleteImage = createAsyncThunk(
    "delete/DeleteImage/admin",
    async(info: any,{ getState } ) => {        
        const state:any = getState()        
        info["AccToken"] = state?.token?.AccToken    
        const res = await axios.put(`${serverAddress}/deleteimage`,info)                
        return res.data
})
export const GetGallery = createAsyncThunk(
    "get/GetGallery",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`/gallery/gallery.json`,
            {
                params:{ t: new Date().getTime()} // removes cache ??
            }
        )           
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})



//* Icecream
export const UpdateIcecream = createAsyncThunk(
    "put/UpdateIcecream/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updateicecream`,info)        
        return res.data
})
export const GetIcecream = createAsyncThunk(
    "get/GetIcecream",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`/icecream/icecream.json`,
            {
                params:{ t: new Date().getTime()} // removes cache ??
            }
        )           
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})




//* Info
export const UpdateInfo = createAsyncThunk(
    "put/UpdateInfo/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updateinfo`,info )        
        return res.data
})
export const GetInfo = createAsyncThunk(
    "get/GetInfo",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`/info/info.json`,
            {
                params:{ t: new Date().getTime()} // removes cache ??
            }
        )           
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})
export const GetInfoChangeAble = createAsyncThunk(
    "get/GetInfoChangeAble",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`/info/changeableInfo.json`,
            {
                params:{ t: new Date().getTime()} // removes cache ??
            }
        )           
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})


//* Menu
export const UpdateMenu = createAsyncThunk(
    "put/UpdateMenu/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updatemenu`,info )
        return res.data
})
export const UpdateLunch = createAsyncThunk(
    "put/UpdateLunch/admin",
    async(info: any,{ getState } ) => {
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updatelunch`,info )
        return res.data
})
export const GetMenu = createAsyncThunk(
    "get/GetMenu",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`/menu/menu.json`,
            {
                params:{ t: new Date().getTime()} // removes cache ??
            }
        )           
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})
export const GetLunch = createAsyncThunk(
    "get/GetLunch",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`/menu/lunch_v${info?.week}.json`,
            {
                params:{ t: new Date().getTime()} // removes cache ??
            }
        )           
        return {statusCode:200,data:res.data}
    } catch (error) {
        console.log(error)   
        return  {statusCode:400}
    }
})

//* SNS
export const GetSns = createAsyncThunk(
    "get/GetSns/admin",
    async(info: any,{ getState } ) => {        
        const state:any = getState()                 
        const res = await axios.get(`${serverAddress}/getsns/?AccToken=${state?.token?.AccToken}`)  
        return res.data
})
export const AddSns = createAsyncThunk(
    "post/AddSns/admin",    
    async(info: any,{ getState } ) => {
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.post(`${serverAddress}/addsns`,info)        
        return res.data
})
export const DeleteSns = createAsyncThunk(
    "delete/DeleteSns/admin",
    async(info: any,{ getState } ) => {        
        const state:any = getState()                  
        const res = await axios.delete(`${serverAddress}/deletesns/?AccToken=${state?.token?.AccToken}&SubscriptionArn=${info?.SubscriptionArn}`)
        return res.data
})







//* User
export const Login = createAsyncThunk(
    "post/Login/admin",    
    async(info: any,{ getState } ) => {             
        const res = await axios.post(`${serverAddress}/login`,info)        
        return res.data
})
export const EditUser = createAsyncThunk(
    "post/EditUser/admin",    
    async(info: any,{ getState } ) => {  
        const state:any = getState()
        info["AccToken"] = state?.token?.AccToken             
        const res = await axios.put(`${serverAddress}/edituser`,info)        
        return res.data
})
export const Logout = createAsyncThunk(
    "delete/Logout/admin",    
    async(info: any,{ getState } ) => {             
        const state:any = getState()
        
        let decodedAccToken:any = jwt_decode(state?.token?.AccToken  );  
        const res = await axios.delete(`${serverAddress}/logout/?userId=${decodedAccToken?.userId}` )        
        return res.data
})
export const ForgotPassword = createAsyncThunk(
    "get/ForgotPassword",    
    async(info: any,{ getState } ) => {                                     
        const res = await axios.post(`${serverAddress}/forgotpassword`,info )        
        return res.data
})
export const ConfirmForgotPassword = createAsyncThunk(
    "get/ConfirmForgotPassword",    
    async(info: any,{ getState } ) => {                                     
        const res = await axios.put(`${serverAddress}/confirmforgotpassword`,info )        
        return res.data
})



export const PostTest = createAsyncThunk(
    "post/PostTest/admin",    
    async(info: any,{ getState } ) => {             
        const res = await axios.post(`${serverAddress}/posttest`,info)        
        return res.data
})
export const GetTest = createAsyncThunk(
    "get/GetTest/admin",    
    async(info: any,{ getState } ) => {             
        const res = await axios.get(`${serverAddress}/gettest/`)        
        return res.data
})











const initalStateValue = {AccToken:""}


const postSlice = createSlice({
    name:"token",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {
        /* builder.addCase(RefreshAccToken.fulfilled, (state, action) => {  
            return FulfilledReducer(action?.payload?.body?.AccToken,action?.payload?.statusCode)                                         
        }) 
        builder.addCase(Login.fulfilled, (state, action) => {      
            console.log("login",action)
            return FulfilledReducer(action?.payload?.body?.AccToken,action?.payload?.statusCode)                         
        }) 
        builder.addCase(GetBookings.fulfilled, (state, action) => {            
            return FulfilledReducer(action?.payload?.body?.AccToken,action?.payload?.statusCode)      
        })  */
        builder.addCase(Logout.fulfilled, (state, action) => {            
            return {AccToken: ""}                                  
        })
        builder.addCase(Logout.rejected, (state, action) => {            
            return {AccToken: ""}                                  
        })
        //AddMatcher triggers on all /fulfilled in this case
        builder.addMatcher((action) => action.type.endsWith('/admin/fulfilled'),(state,action)=>{      
            if(action?.payload?.statusCode === 401){
                return {AccToken: ""}
            }           
            if(action?.payload?.body?.AccToken){
                return {AccToken : action?.payload?.body?.AccToken}                                
            }
            return
        })             
    }
})

const { reducer } = postSlice

export default reducer

 