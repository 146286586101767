import React,{ useLayoutEffect, useRef, useState} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// REDUX


// REACT ICONS

// SELF IMPORTS
import { Loading } from '../../components/index'
import './Header.css'

interface HeaderInit {
  Title_1: string
  Title_2: string
  SubTitle_1: string
  Image: string
  Message: string
}




gsap.registerPlugin(ScrollTrigger);
const Header = ({Title_1,Title_2,SubTitle_1,Image, Message}:HeaderInit) => {
    
  const header_ref = useRef(null)

  //* States
  const [loading, setLoading] = useState(true)

  //* GSAP

/* Page load => fade in main text*/
useLayoutEffect(() => {
  const ctx = gsap.context(()=>{ 
    gsap.fromTo('.header-img',{
      filter: "blur(10px) brightness(0.8)",
    },{
      filter: "blur(0px) brightness(0.8)", 
      duration: 4,
      ease: 'power3.inOut'
    })


    gsap.fromTo('.header__title',{
      autoAlpha:0
    },{
      autoAlpha:1,
      duration: 4,
      ease: 'power3.inOut'
    })
      
  },header_ref)

  return () => {
    ctx.revert()
  };
}, [])
  return (
    <section className='container__header flex__center-c' ref={header_ref}>     


      {/* Image */}
      <img className="header-img" src={Image} alt="header" onLoad={()=>setLoading(false)} onError={()=>setLoading(false)}/>   

   

      {/* Title */}
      <div className='header__title flex__center-c'>
        <h1 className='h1__font-large'>{Title_1}</h1>                
        <h1 className='h1__font-large'>{Title_2}</h1>                
       {/*  <img style={{width:"6rem",height:"6rem"}} src={`/images/mainlogo.webp`} alt='logo'/> */}
        <p className='p__font-small'>{SubTitle_1}</p>                     
      </div>
      

        {Message && 
        <div className="flex__center-c" style={{backgroundColor:"#0000007a",width:"100%"}}>
            <p className='p__font-medium' style={{textAlign:"center"}}>{Message}</p>     
        </div>
        }

      


      {/* LOADING SCREEN */}
      {loading && 
        <Loading />
      }
   
    </section>
  )
}

export default Header