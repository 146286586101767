import {useEffect, useState} from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX

import { GetInfo } from '../../slices/infoReducer'; 
import { GetInfoChangeAble, UpdateInfo } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// SELF IMPORTS
import './InfoDashboard.css'
const InfoDashboard = () => {
  type AppDispatch = typeof store.dispatch
  const useAppDispatch = () => useDispatch<AppDispatch>()
  const dispatch = useAppDispatch()

  //* VARIABLES
  const [information, setInformation] = useState<any>({})
  const [dbInformation, setDbInformation] = useState<any>({})  
  const [message, setMessage] = useState<string>("")

    //* useEffect
    // Gets info from s3
    useEffect(() => {       
        dispatch(GetInfoChangeAble({}))
        .unwrap()
        .then(data => {            
            setInformation(data)                       
        })  

     /*    fetch(`/info/changeableInfo.json`)
        .then(response => {
     
        return response.json();
        }).then(data => {          
            setInformation(data);                
        }).catch((e: Error) => {         
            return
        }); */
    }, [])

    // Gets info from db
    useEffect(() => {
        dispatch(GetInfo({}))            
        .then(unwrapResult)
        .then((res) => {
          
            if(res?.statusCode === 200){  
            setDbInformation(res?.body?.data)
            }    
                
        })       
        const tempData = {Booking_Active:true,Contact_Active:true}        
        setDbInformation(tempData) 
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[] )

    //* Functions
    // Changes inputs/textarea for s3
    const handleChange = (e:any,category:string) => {
        const { name, value } = e?.target  
        const nestedObj = {...information[category], [name]:value  }
        const headerObj = {...information, [category]:nestedObj  }
        setInformation(headerObj)
    }
    // Changes inputs/textarea for db
    const handleChangeDbInfo = (e:any) => {   
        const { name,value } = e?.target    
        if(name === "Booking_Active" || name === "Contact_Active"){
        setDbInformation((prev:any)=>({...prev,[name]:!dbInformation?.[name]}))
        }else{
        setDbInformation((prev:any)=>({...prev,[name]:value}))
        }
    }
    // Updates Info in db and s3
    const submit = async() => {        
        setMessage("laddar...") 
        
		dispatch(UpdateInfo({information, dbInformation, }))
			.then(unwrapResult)
			.then((res:any) => {                              
				
				if(res?.statusCode === 200){                
					setMessage("Success")
				}else{                
					setMessage("Något gick fel")
				}
			})
        
         
        
    }
    // click on a date to add an Closed event for the booking calender
    const handleCalenderDates = (e:any) =>{
        const { value } = e.target       
        setDbInformation((prev:any)=>({...prev,Booking_turnOffDatesOnCalender:  [...dbInformation?.Booking_turnOffDatesOnCalender, {date:Date.parse(`${value}T00:00:00`), title:""}]   }))  
    }
    // change the title text of the event from the booking calender
    const addTitleToCalender = (e:any,i:number) =>{
        const { name, value } = e.target                   
        const obj:any = dbInformation?.Booking_turnOffDatesOnCalender
        obj[i][name] = value     
        setDbInformation((prevState: any) => (
        {      
            ...prevState,  Booking_turnOffDatesOnCalender:obj 
        }))   
    }
    // click on a current date to remove an event from the booking calender
    const removeTimeFromCalender = (i:number) =>{    
        setDbInformation((prevState :any) => ({
        ...prevState,
        Booking_turnOffDatesOnCalender: dbInformation?.Booking_turnOffDatesOnCalender.filter((a:any)  => 
            dbInformation?.Booking_turnOffDatesOnCalender.indexOf(a) !== i
            ) 
        })) 
    }


 
  return (
    <div className='container__infodashboard flex__center-c'>

      <h1 className='h1__font-medium'>Information</h1>

      <button onClick={submit}>Updatera data</button>
      <p>{message}</p>

      {Object?.keys(information).map((headerKey,i)=>{
        return(
          <div className='infodasboard__category' key={`category${i}`}>
            <h3>{headerKey}</h3>
            {Object.keys(information[headerKey]).map((itemKey,j)=>(
              <div className='infodashboard__item flex-c' key={`item${i}${j}`}>
                <label>{itemKey}</label>              
                <textarea value={information?.[headerKey]?.[itemKey]} name={itemKey} onChange={(e)=>handleChange(e,headerKey)}></textarea>
              </div>
            ))}
          </div>
        )
      })}


      {dbInformation && 
      <div className='infodasboard__dbinformation'>

        <div className='infodashboard__item flex-c' >
          <label>Antal bord som finns i restaurangen</label>              
          <input type="number" value={dbInformation?.TotalNumberOfTables} name={"TotalNumberOfTables"} onChange={handleChangeDbInfo}></input>
        </div>

        <div className='infodashboard__item flex-c' >
          <label>Max antal gäster per bokning</label>              
          <input type="number" value={dbInformation?.maxNumberOfGuestPerReservation} name={"maxNumberOfGuestPerReservation"} onChange={handleChangeDbInfo}></input>
        </div>

        


        <div className='dbinformation flex-c'>

          <h3>Lunch inställningar </h3>

          <div className='dbinformation__item flex-c' >
            <label>Hur många minuter en gäst kan boka ett bord vid lunch (tex 90 = 1.5 timmar ) </label>              
            <input type="number" value={dbInformation?.reservationDurationLunch} name={"reservationDurationLunch"} onChange={handleChangeDbInfo}></input>
          </div>

          <div className='dbinformation__item flex-c' >
            <label>Vilka tider varje timme kan bokningar ske </label>                          
            <select id="fCategory" onChange={handleChangeDbInfo} value={dbInformation?.timeIntervallBetweenEachBooking} name="timeIntervallBetweenEachBooking">
              <option value={3600000}>Varje heltimme (tex 11:00, 16:00 eller 22:00)</option>
              <option value={1800000}>Varje halvtimme (text 10:30, 16:00 eller 22:00)</option>              
              <option value={900000}>Varje kvart (text 12:45, 16:00 eller 22:30)</option>              
            </select>
          </div>

          <div className='dbinformation__item flex-c' >
            <label>Öppettider för Lunch (tex 11:00 = 11 0 ) klockan går från 0-24 timmar / 0-59 minuter</label>    
            <div className='dbinformation__item-time flex'>              
              <p>Lunch öppnar kl. </p>
              <input type="number" value={dbInformation?.reservationTimeStartHourLunch} name={"reservationTimeStartHourLunch"} onChange={handleChangeDbInfo}></input>
              <input type="number" value={dbInformation?.reservationTimeStartMinutesLunch} name={"reservationTimeStartMinutesLunch"} onChange={handleChangeDbInfo}></input>
            </div>         
            <div className='dbinformation__item-time flex'>              
              <p>Lunch stänger kl. </p>
              <input type="number" value={dbInformation?.reservationTimeEndHourLunch} name={"reservationTimeEndHourLunch"} onChange={handleChangeDbInfo}></input>
              <input type="number" value={dbInformation?.reservationTimeEndMinutesLunch} name={"reservationTimeEndMinutesLunch"} onChange={handleChangeDbInfo}></input>
            </div> 
          </div>

        


        

          

        </div>




        <div className='dbinformation flex-c'>

          <h3>Middags inställningar </h3>

          <div className='dbinformation__item flex-c' >
            <label>Hur många minuter en gäst kan boka ett bord vid middag (tex 120 = 2 timmar ) </label>              
            <input type="number" value={dbInformation?.reservationDurationDinner} name={"reservationDurationDinner"} onChange={handleChangeDbInfo}></input>
          </div>

        


          <div className='dbinformation__item flex-c' >
            <label>Öppettider för Middag (tex 16:30 = 16 30 ) klockan går från 0-24 timmar / 0-59 minuter</label>    
            <div className='dbinformation__item-time flex'>              
              <p>Middag öppnar kl. </p>
              <input type="number" value={dbInformation?.reservationTimeStartHourDinner} name={"reservationTimeStartHourDinner"} onChange={handleChangeDbInfo}></input>
              <input type="number" value={dbInformation?.reservationTimeStartMinutesDinner} name={"reservationTimeStartMinutesDinner"} onChange={handleChangeDbInfo}></input>
            </div>         
            <div className='dbinformation__item-time flex'>              
              <p>Middag stänger kl. </p>
              <input type="number" value={dbInformation?.reservationTimeEndHourDinner} name={"reservationTimeEndHourDinner"} onChange={handleChangeDbInfo}></input>
              <input type="number" value={dbInformation?.reservationTimeEndMinutesDinner} name={"reservationTimeEndMinutesDinner"} onChange={handleChangeDbInfo}></input>
            </div> 
          </div>

        

          

        </div>


        <div className="checkbox-wrapper-8">
          <p>Stäng av eller på Bokningssystem</p>
          <input className="tgl tgl-skewed" id="cb3-8-shop" type="checkbox" name="Booking_Active" checked={dbInformation?.Booking_Active} value={dbInformation?.Booking_Active} onChange={handleChangeDbInfo}/>
          <label className="tgl-btn" data-tg-off="OFF" data-tg-on="ON" htmlFor="cb3-8-shop"></label>
        </div>

        <div className="checkbox-wrapper-8">
          <p>Stäng av eller på kontaktformuläret</p>
          <input className="tgl tgl-skewed" id="cb3-8-contact" type="checkbox" name="Contact_Active" checked={dbInformation?.Contact_Active} value={dbInformation?.Contact_Active} onChange={handleChangeDbInfo}/>
          <label className="tgl-btn" data-tg-off="OFF" data-tg-on="ON" htmlFor="cb3-8-contact"></label>
        </div>
    




        <div className='dashboardinfo__booking__close'>
          <p className=''>Stäng av bokning vid ett visst datum</p>
          <p>Alla ändringar sker enbart efter "uppdatera data" har klickats</p>                          
          <input type="date" name="Booking_turnOffDatesOnCalender" onChange={handleCalenderDates}></input>            
            <div>
              {dbInformation?.Booking_turnOffDatesOnCalender?.map((item:any,i:number)=>(
                <p key={i}>
                  {new Date(item?.date).toLocaleDateString()}
                  <input type="text" onChange={(e)=>addTitleToCalender(e,i)} name='title' value={item?.title}></input>
                  <button onClick={()=>removeTimeFromCalender(i)}>Delete</button>
                </p>
              ))}
            </div>
          </div>





      </div>
      }
    </div>
  )
}

export default InfoDashboard