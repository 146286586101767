import {  useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'



// REDUX

import { Login,ForgotPassword } from '../../slices/tokenReducer'; 
import  store  from '../../store'


// REACT ICONS


// SELF IMPORTS

import { DashboardNavbar } from '../../components/index'
import { SettingsDashboard, BookingDashboard, InfoDashboard,GalleryDashboard,IcecreamDashboard, EventDashboard,MenuDashboard,LunchDashboard, SnsDashboard} from '../index'

import './Dashboard.css'


const Dashboard = () => {
	type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()  
	

	// SELECTOR
	//var AccToken = localStorage.getItem("AccToken") ?  JSON.parse(localStorage.getItem("AccToken")|| '{}') : null;    
	/* let AccToken = store.getState().token.AccToken;    */
	const AccToken = useSelector((state: any) => state?.token?.AccToken)



	//* Variables 
	interface init {
		email: string
		password: string
	}
	const [credentials, setCredentials] = useState<init>({
		email: "", //!dev
		password:""//!dev
	})
	const [message, setMessage] = useState<string>("")
	const [forgotPasswordBoolean, setForgotPasswordBoolean] = useState<boolean>(false)
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>("")



  	//* Functions
	const ResetPwd = (e:any) => {
		setMessage("Laddar...")
		e.preventDefault()
		dispatch(ForgotPassword({email: forgotPasswordEmail}))            
			.unwrap()
			.then((res:any) => {								
				setMessage("Om det finns ett konto med denna mail, så kommer den få ett mail")				
			})  
	}
	const LoginSubmit = (e:any) => {
		setMessage("Laddar...")
		e.preventDefault()
		dispatch(Login({email: credentials?.email, password: credentials?.password}))            
			.unwrap()
			.then((res:any) => {
				
				if(res?.statusCode === 200){
                    setCredentials({
                        email: "", 
                        password:""
                    })
					setMessage("")
				}else if(res?.body?.message){
					setMessage(res?.body?.message)
				}else{
					setMessage("Ett oväntat fel uppkom, försök igen")
				}
			})  

	}

	// Adds data do cretendial state
	const HandleForm = (e:any) => {
		setCredentials(prev=> ({...prev, [e.target.name]:e.target.value}))
	}
  

  return (
    <div className='container__dashboard'>
      {AccToken ? 
      <div>
      <DashboardNavbar />
      <Routes>
        <Route path="/" element={
          <div className='container__dashboard flex__center-c'>
            <h1 className='h1__font-medium'>Välkommen</h1>			
          </div>  
        } /> 
        <Route path="/booking" element={<BookingDashboard />} /> 
        <Route path="/info" element={<InfoDashboard />} /> 
		<Route path="/gallery" element={<GalleryDashboard />} /> 
		<Route path="/icecream" element={<IcecreamDashboard />} /> 
		<Route path="/event" element={<EventDashboard />} /> 
		<Route path="/menu" element={<MenuDashboard />} /> 
		<Route path="/lunch" element={<LunchDashboard />} /> 
		<Route path="/sns" element={<SnsDashboard />} />
		<Route path="/settings" element={<SettingsDashboard />} />
		
		
      {/*   <Route path="/menu" element={<DashboardMenu />} />
        <Route path="/menuspecial" element={<DashboardMenuSpecial />} />
        <Route path="/media" element={<DashboardMedia />} />
        <Route path="/info" element={<DashboardInfo />} />      
        <Route path="/menupickup" element={<DashboardMenuPickup />} />
        <Route path="/createpdf" element={<Createpdf />} />
        <Route path="/lunchmenu" element={<DashboardLunchMenu />} />
        <Route path="/lunchmenuweekend" element={<DashboardLunchdMenuWeekend />} />        
        <Route path="/payments" element={<DashboardPayments />} />
        <Route path="/takeaway" element={<DashboardTakeaway />} />
        <Route path="/settings" element={<DashboardSettings />} />
        <Route path="/newsletter" element={<DashboardNewsletter />} />
        
        <Route path="/news" element={<DashboardNews />} />
         */}
        
        
        
        
        
        
      </Routes>
      </div>
      :
      <div className='container__dashboard flex__center-c'>
        <form className='flex__center-c'>
          <h3 className='h4__font-medium'>Login</h3>
          <label className='p__font-small'>Email</label>
          <input type="text" name='email' onChange={HandleForm}></input>
          <label className='p__font-small'>Password</label>
          <input type="password" name='password' onChange={HandleForm}></input>
          <button className='p__font-small' onClick={LoginSubmit}>Login</button>              
          <p  className='p__font-small' onClick={()=>setForgotPasswordBoolean(true)}>Glömt lösenordet?</p>
		  <p className='p__font-small'>{message}</p>
          <input type="submit" hidden />
        </form>
		
    </div>      
    }


	
	{forgotPasswordBoolean &&
	<div className='forgotpassword flex__center-c'>
		<form className='flex__center-c'>
			<p  className='p__font-xsmall' onClick={()=>setForgotPasswordBoolean(false)}>Tillbaka</p>
			<h3 className='h4__font-medium'>Glömt lösenordet</h3>
			<label className='p__font-small'>Email</label>
			<input type="text" name='email' value={forgotPasswordEmail} onChange={(e)=>setForgotPasswordEmail(e?.target?.value)}></input>	  
			<button className='p__font-small' onClick={ResetPwd}>Skicka</button>              	  
			<p className='p__font-small'>{message}</p>
			<input type="submit" hidden />
		</form>	
	</div>	
	}

    </div>
  )

}

export default Dashboard