import { GetInfo } from '../slices/infoReducer'


import store from '../store'

export const FetchInfoFromStore = async() => {
    try {
            
        let info = store.getState().info           
     
        if(Object.keys(info).length === 0){
            await store.dispatch(GetInfo({}))
                .unwrap()
                .then((res) => {            
                    info = store.getState().info                                                     
                   
                })
                .catch((error) => {
                    console.log(error)                                        
                    return {}
                })
        }
        return info   

    } catch (error) {
        console.log("Info error",error)              
        return 500
    }

}