import { useState, useEffect } from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REDUX
import { DeleteSns, AddSns, GetSns } from '../../slices/tokenReducer'; 
import  store  from '../../store'

// ICONS


// SELF IMPORTS

import './SnsDashboard.css'


const SnsDashboard = () => {
  type AppDispatch = typeof store.dispatch
  const useAppDispatch = () => useDispatch<AppDispatch>()
  const dispatch = useAppDispatch()

  //* VARIABLES    
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [information, setInformation] = useState<string []>([])


  //* FUNCTIONS

  // POST SNS ENDPOINT NUMBER  
  const submit = async() => {    
	setMessage("laddar...")      
	dispatch(AddSns({Phone:phone}))
		.then(unwrapResult)
		.then((res:any) => {		
			if(res?.statusCode === 200){               						                                   
				setMessage("Nummret är tillagt")						
			}else{                
				setMessage("Något gick fel")
			}	
		})    
  }

  
  // Delete SNS ENDPOINT NUMBER  
  const DeleteSubmit = (SubscriptionArn:string):void => {
	setMessage("laddar...")      
	dispatch(DeleteSns({SubscriptionArn}))
		.then(unwrapResult)
		.then((res:any) => {		
			if(res?.statusCode === 200){               						                                   
				setMessage("Nummret är borttaget")						
			}else{                
				setMessage("Något gick fel")
			}	
		}) 
  }        
  // GET SNS ENDPOINT NUMBER
  useEffect(() => {       	    
	setMessage("laddar...")      
	dispatch(GetSns({}))
		.then(unwrapResult)
		.then((res:any) => {		
			
			if(res?.statusCode === 200){               						                                   
				setInformation(res?.body?.data)				
				setMessage("")      
			}else{                
				setMessage("Något gick fel")
			}	
		}) 
  }, [])



  return (
    <div className='container__snsdashboard flex__center-c'>

      <div className='snsdashboard__section flex__center-c'>
        <button  onClick={submit}>Lägg till nytt nummer</button>
        <label>Lägg till ett nytt telefonnummer för notifikationer</label>
        <input type='text' value={phone} onChange={(e)=>setPhone(e?.target?.value)} />
        <h5 className='h5__font-base'>{message}</h5>                                   
      </div>

      <div className='snsdashboard__numbers flex__center-c'>        
          {information?.map((sub:any,i)=>(
            <div className='snsdashboard__number-item' key={i}>
              <p className='p__font-small'>{sub?.Endpoint}</p>
              <button onClick={()=>DeleteSubmit(sub?.SubscriptionArn)}>Delete</button>
            </div>
          ))}      
        
      </div>
        
      

    </div>
  )
}

export default SnsDashboard